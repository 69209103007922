import {
  CalculateQuoteRequest,
  CalculateQuoteResponse,
  CheckSimulationStatusRequest,
  CheckSimulationStatusResponse,
  ConvertQuoteToPolicyRequest,
  ConvertQuoteToPolicyResponse,
  CreateQuoteRequest,
  CreateQuoteResponse,
  GetTemplateResponse,
  QuoteEditDetails,
  QuoteInitiateDetails,
  QuoteListResponse,
  QuoteViewDetailsResonse,
  SendEmailVerificationRequest,
  SendVerificationResponse
} from './types';
import { createRequest } from '../../api-definition';

export const getInitiate = createRequest<QuoteInitiateDetails, string>({
  path: 'Quotes/GetInitiate',
  method: 'GET'
});

export const getQuoteDetailsById = createRequest<QuoteEditDetails, number>({
  path: 'Quotes/GetQuoteDetailsById',
  method: 'GET'
});

export const getAllList = createRequest<QuoteListResponse, void>({
  path: 'Quotes/GetAllList',
  method: 'GET'
});

export const getViewById = createRequest<QuoteViewDetailsResonse, number>({
  path: 'Quotes/GetViewById',
  method: 'GET'
});

export const sendEmailVerificationLink = createRequest<
  SendVerificationResponse,
  SendEmailVerificationRequest
>({
  path: 'Quotes/SendEmailVerificationLink',
  method: 'POST'
});

export const calculateQuote = createRequest<
  CalculateQuoteResponse,
  CalculateQuoteRequest
>({
  path: 'Quotes/Calculate',
  method: 'POST'
});
export const createQuote = createRequest<
  CreateQuoteResponse,
  CreateQuoteRequest
>({
  path: 'Quotes/Create',
  method: 'POST'
});

export const convertQuoteToPolicy = createRequest<
  ConvertQuoteToPolicyResponse,
  ConvertQuoteToPolicyRequest
>({
  path: 'Quotes/QuoteToPolicy',
  method: 'POST'
});

export const simulateQuote = createRequest<
  CheckSimulationStatusResponse,
  CheckSimulationStatusRequest
>({
  path: 'Quotes/TryAndPlay',
  method: 'POST'
});

export const getTemplate = createRequest<
  GetTemplateResponse,
  { productId: string }
>({
  path: 'Quotes/Template',
  method: 'GET'
});
