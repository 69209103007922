import { createPayment } from '../requests';
import { createMutation } from '../store';

const useCreatePayment = createMutation({
  request: createPayment
});

const payment = {
  useCreatePayment
};

export default payment;
