import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ActivityIndicator, Button, IconButton } from '@components/index';
import { useToggle } from '@lib/utils';

import ChatInput from './ChatInput';
import useDiscountCode from '../hooks/useDiscountCode';
import { useOnboardingTranslation } from '../translation';

type Props = {
  price: string;
  fullPrice: string;
  priceUnit?: string;
  discountCode?: string;
  isLoading?: boolean;
  isDiscountCodeEditable?: boolean;
  priceInfo?: string;
};

const PriceInfo = ({
  fullPrice,
  priceUnit,
  price,
  discountCode,
  isLoading,
  isDiscountCodeEditable,
  priceInfo
}: Props) => {
  const t = useOnboardingTranslation();
  const { state: isEditingDiscountCode, toggle: toggleEditingDiscountCode } =
    useToggle();
  const { setDiscountCode, isLoading: isEstimating } = useDiscountCode(
    toggleEditingDiscountCode
  );

  const priceInfoClasses = twMerge('flex flex-row items-end justify-between');

  const handleSubmitDiscountCode = (value: string) => {
    setDiscountCode(value);
  };

  const handleResetDiscountCode = () => {
    setDiscountCode(undefined);
    isEditingDiscountCode && toggleEditingDiscountCode();
  };

  return (
    <div className="space-y-4">
      <div className={priceInfoClasses}>
        <div className="text-xl font-semibold">
          {t('PRICE_SUGGESTION_SCREEN.YOUR_PRICE')}
        </div>
        <div className="flex flex-row items-end align-bottom text-[40px] font-semibold">
          {Number(fullPrice) > Number(price) && !isLoading && (
            <span className="mb-0 ml-4 align-top text-xl font-medium line-through">
              {Number(fullPrice).toFixed(2)}{' '}
              {priceUnit?.split('/')?.[0] || 'kr'}
            </span>
          )}
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <>
              <span className="mb-0 ml-4 align-top text-4xl font-semibold">
                {price}
                {priceUnit}
              </span>
            </>
          )}
        </div>
      </div>
      {priceInfo && <span className="text-sm">{priceInfo}</span>}
      <div className="border-y-2 py-4 uppercase">
        {!!discountCode && (
          <div className="flex items-center justify-between py-1 text-sm font-bold leading-5 tracking-[0.96px]">
            {t('PRICE_SUGGESTION_SCREEN.DISCOUNT_CODE_ACTIVE', {
              discountCode
            })}
            {isDiscountCodeEditable && (
              <IconButton
                name="close"
                isLoading={isEstimating}
                className="h-10 w-10 bg-tertiary active:bg-pressedTertiary"
                onClick={handleResetDiscountCode}
                activityIndicatorContainer="ml-0"
              />
            )}
          </div>
        )}
        {isEditingDiscountCode && !discountCode && (
          <ChatInput
            className="h-[48px] animate-fade-in"
            onSend={handleSubmitDiscountCode}
            isLoading={isEstimating}
            placeholder={t('PRICE_SUGGESTION_SCREEN.ADD_DISCOUNT_CODE')}
            iconButtonClasses="h-[36px] w-[36px] bg-primary active:bg-pressedPrimary"
            formClasses="h-[48px]"
          />
        )}
        {!isEditingDiscountCode && !discountCode && !isEstimating && (
          <Button
            icon="add"
            onClick={toggleEditingDiscountCode}
            className="-ml-4 h-[48px] w-auto justify-start bg-transparent px-4"
          >
            {t('PRICE_SUGGESTION_SCREEN.ADD_DISCOUNT_CODE')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PriceInfo;
