import axios from 'axios';

import { getParameterOptions } from './getParameterOptions';
import { DefaultError, RawError, RequestOptions } from './types';

export const createRequest =
  <
    BaseUrl extends string,
    Path extends string,
    Result,
    Parameters = void,
    ErrorType = DefaultError
  >({
    baseUrl,
    method,
    path,
    pathParameters = [],
    headers = {},
    axiosClient
  }: RequestOptions<BaseUrl, Path>) =>
  async (parameters: Parameters) => {
    const requestClient = axiosClient ?? axios;

    const pathParametersString = `${
      pathParameters.length > 0 ? '/' : ''
    }${pathParameters.join('/')}`;

    try {
      const response = await requestClient<Result>({
        baseURL: baseUrl,
        url: `/${path}${pathParametersString}`,
        method,
        headers,
        ...getParameterOptions(method, parameters)
      });
      return response.data;
    } catch (error: unknown) {
      const {
        code,
        response: response_1,
        name,
        message,
        status,
        stack
      } = error as RawError<Error>;
      const defaultError: DefaultError<Error> = {
        status,
        code,
        data: response_1?.data?.error,
        name,
        message,
        stack
      };
      throw defaultError as ErrorType;
    }
  };
