import { getProducts } from '../requests';
import { createQueryWithMock } from '../store';

const useGetProducts = createQueryWithMock('getProducts', getProducts, {
  result: {
    productList: [
      {
        productId: '1',
        packageId: 123,
        name: 'sejfa',
        image: ''
      }
    ]
  }
});

const products = {
  useGetProducts
};

export default products;
