import client from './client';
import { CONTENT_ENTRY } from './types';

export const findSlugs = async () => {
  const entries = await client.getEntries({
    content_type: CONTENT_ENTRY['referral_page'],
    select: ['fields.slug']
  });

  const slugs = entries.items.map((entry) => entry.fields.slug as string);

  return slugs;
};

export const findEntryBySlug = async (slug: string | string[]) => {
  const entries = await client.getEntries({
    content_type: CONTENT_ENTRY['referral_page'],
    'fields.slug': slug
  });

  if (entries.items.length === 0) {
    return null;
  }

  return entries.items[0];
};
