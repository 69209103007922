import { ContentfulClientApi, createClient } from 'contentful';

import env from '@core/env-config';

// eslint-disable-next-line @typescript-eslint/no-var-requires

const client: ContentfulClientApi<undefined> = createClient({
  space: env.CONTENTFUL_SPACE_ID!,
  accessToken: env.CONTENTFUL_ACCESS_TOKEN!
});

export default client;
