export const convertNumericStringToNumber = (
  str: string
): number | undefined => {
  // Remove any whitespace from the string
  str = str.replace(/\s/g, '');

  // Check if the string is empty or not a number
  if (str === '' || isNaN(+str)) {
    return undefined;
  }

  // Convert the string to a number
  const num = +str;

  return num;
};
