import { useFeature } from '@core/feature-toggles';

type UseKillswitchResult = {
  isKillswitchEnabled: boolean;
};

export const useKillswitch = (): UseKillswitchResult => {
  const { enabled: killswitchEnabled, value: killswitchValue } =
    useFeature('killswitch');

  const isKillswitchEnabled = !!(killswitchEnabled && killswitchValue);

  return {
    isKillswitchEnabled
  };
};
