import React from 'react';

import { Divider, Datepicker } from '@components/index';
import { date } from '@lib/date';

import PaymentFrequencyOptions from './PaymentFrequencyOptions';
import InformationBlock from '../../../components/InformationBlock/InformationBlock';
import { updatePriceStore, useSelectFromPriceStore } from '../stores';
import { useOnboardingTranslation } from '../translation';

const FrequencyBlock = () => {
  const updateStartDate = updatePriceStore('updatePrice');
  const startDate = useSelectFromPriceStore((state) => state.startDate);

  const t = useOnboardingTranslation();

  const handleDateChange = (date: Date) => {
    updateStartDate({ startDate: date.toISOString() });
  };

  return (
    <InformationBlock>
      <p className="text-2xl font-semibold leading-tight">
        {t('PRICE_SUGGESTION_SCREEN.SELECT_START_DATE')}
      </p>
      <Datepicker
        title={t('PRICE_SUGGESTION_SCREEN.SELECT_START_DATE')}
        onDateChange={handleDateChange}
        date={new Date(startDate ?? '')}
        maxDate={date.add(new Date(), { years: 1, days: -1 })}
      />
      <Divider className="mt-6 bg-black" />
      <PaymentFrequencyOptions />
    </InformationBlock>
  );
};

export default FrequencyBlock;
