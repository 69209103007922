import React, { PropsWithChildren } from 'react';

import { Document as RichTextDocument } from '@contentful/rich-text-types';
import Image, { StaticImageData } from 'next/image';

import RichText from '@components/RichText';

type RichTextPageProps = {
  src: StaticImageData;
  Text?: RichTextDocument;
} & PropsWithChildren;

const RichTextPage = ({ src, children, Text }: RichTextPageProps) => {
  return (
    <div className="grid grid-cols-1 rounded-b-[32px] bg-white p-4 md:grid-cols-2 md:py-28">
      <div className="sm:flex items-start justify-center">
        <div className="hidden md:sticky md:top-28 md:block">
          <div className="flex items-center justify-center ">
            <Image
              src={src}
              alt="onboarding-success-image"
              style={{
                borderRadius: '32px',
                width: '80%'
              }}
            />
          </div>
        </div>
      </div>
      <div className="md:pr-28">
        {Text && <RichText content={Text} />}
        {children}
      </div>
    </div>
  );
};

export default RichTextPage;
