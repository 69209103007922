import { Entry, EntryFieldTypes, EntrySkeletonType } from 'contentful';

export type ContentType = {
  price_suggestion_chat: PriceSuggestionChatContentSkeleton;
  price_suggestion_chat_simplified: PriceSuggestionInsurelyChatContentSkeleton;
  when_is_it_sejf_block: SejfBlockContentSkeleton;
  common_questions_block: SejfBlockContentSkeleton;
  sejf_value_modal: GeneralInsuranceInfoModalContentSkeleton;
  sejf_deductible_modal: GeneralInsuranceInfoModalContentSkeleton;
  sejf_trip_modal: GeneralInsuranceInfoModalContentSkeleton;
  price_suggestion_edit_info: GeneralInsuranceInfoModalContentSkeleton;
  super_sejf_modal: GeneralInsuranceInfoModalContentSkeleton;
  limited_onboarding_modal: GeneralInsuranceInfoModalContentSkeleton;
  chat_section: ChatSectionsContentSkeleton;
  price_suggestion_block: PriceSuggestionBlockContentSkeleton;
  identification_screen: IdentificationScreenContentSkeleton;
  cancel_onboarding_modal: GenereicTextSectionContentSkeleton;
  payment_failed_modal: GenereicTextSectionContentSkeleton;
  important_document_block: ImportantDocumentBlockContentSkeleton;
  cookies_sejfa: GenericMarkdownPageContentSkeleton;
  privacy_sejfa: GenericMarkdownPageContentSkeleton;
  terms_sejfa: GenericMarkdownPageContentSkeleton;
  complaints_sejfa: GenericMarkdownPageContentSkeleton;
  cookies_lf: GenericMarkdownPageContentSkeleton;
  privacy_lf: GenericMarkdownPageContentSkeleton;
  price_suggestion_insurely_chat: PriceSuggestionInsurelyChatContentSkeleton;
  your_integrity: GenericMarkdownPageContentSkeleton;
  early_adopter: GenericMarkdownPageContentSkeleton;
  participating_companies: GenericMarkdownPageContentSkeleton;
  referral_page: ReferralPageContentSkeleton;
};

export type ContentTypeWrapper<TContentType extends EntrySkeletonType> = Entry<
  TContentType,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

export const CONTENT_ENTRY: Record<keyof ContentType, string> = {
  price_suggestion_chat: 'nbqJXHRlMAZt5UgBMyURt',
  price_suggestion_chat_simplified: '2GiUWJeb97kh8LjU2ayXnR',
  price_suggestion_insurely_chat: '2sBy6kLQwZBZ2VWzXrGWMT',
  when_is_it_sejf_block: '6pqfbhz1r1cg2fSnwLTiR2',
  common_questions_block: '1uE20oEcI9UOSAXx7you8z',
  sejf_value_modal: '1wdRcRudptG9hoOonPcueU',
  sejf_deductible_modal: '4tteJPAwHEDy4VAEQnDdnN',
  sejf_trip_modal: '3kiqPllHscPlm44Y9osZuu',
  super_sejf_modal: '2WWLDMLO1BDSE3TYZeFPny',
  limited_onboarding_modal: '2rUTm16t100Tk3awRoRfsl',
  cancel_onboarding_modal: '5fYGVtDB7SSWwxytKnYTkx',
  price_suggestion_edit_info: '5F1DMHXnqVX2oTRB7dGgSz',
  chat_section: '38BqFutXZX1JYyJ7mn7grM',
  price_suggestion_block: '3DMls6TkrtUCtcIMFSvcnq',
  identification_screen: '5EdOgm8We0LckbBkZsjHis',
  payment_failed_modal: '4hytIcHb0X8Cj6fjRmZrpn',
  important_document_block: '38GXA9NYkPVwWhOZklH3jJ',
  cookies_sejfa: '3CNcKjJswPK3NbhniOFRWR',
  privacy_sejfa: '5SRIeWJv0bz912Cdtffpag',
  terms_sejfa: '4qVcrXQyJTrdN189OM7vGu',
  complaints_sejfa: '4vQY3w2kRQwfceBrllyfGx',
  cookies_lf: 'TA4Gfbq2yUTc772wh7rqA',
  privacy_lf: '341xtjvl9tKWHGGkuSPw7Y',
  your_integrity: '5NnoxWAvMGuzNnYPgcKJj4',
  early_adopter: 'zCIOwNXglDqJtlBVAPIwL',
  participating_companies: '3XOK6HihzYhxRjd1viS1f4',
  referral_page: 'pageReferral'
} as const;

export type PriceSuggestionChatContentSkeleton = {
  fields: PriceSuggestionChatContent;
  contentTypeId: 'priceSuggestionChatContent';
};
export type PriceSuggestionChatContent = {
  residenceTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  ageTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  postalCodeTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  residentTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  emailTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
};

export type PriceSuggestionInsurelyChatContentSkeleton = {
  fields: PriceSuggestionInsurelyChatContent;
  contentTypeId: 'priceSuggestionContentInsurely';
};
export type PriceSuggestionInsurelyChatContent = {
  accomodationTypeTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  ageTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  postalCodeTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  residentTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  emailTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  phoneNumberTexts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
};

export type SejfBlockContent = {
  title: EntryFieldTypes.Text;
  dropdownBlocks: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<DropdownContentSkeleton>
  >;
};

export type SejfBlockContentSkeleton = {
  contentTypeId: 'sejfBlock';
  fields: SejfBlockContent;
};

export type DropdownContentSkeleton = {
  contentTypeId: 'sejfDropdown';
  fields: DropdownContent;
};

export type DropdownContentSkeletonEntry = Entry<
  DropdownContentSkeleton,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

export type DropdownContent = {
  contentTypeId: 'sejfDropdown';
  title: EntryFieldTypes.Text;
  subtitle: EntryFieldTypes.Text;
  texts: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
};

export type CategoryTextsContentSkeleton = {
  contentTypeId: 'categoryTexts';
  fields: {
    categories: EntryFieldTypes.Array<
      EntryFieldTypes.EntryLink<CategoryTextContentSkeleton>
    >;
  };
};

export type CategoryTextContentSkeleton = {
  contentTypeId: 'categoryText';
  fields: CategoryTextContent;
};

export type CategoryTextContent = {
  categoryCode: EntryFieldTypes.Text;
  category: EntryFieldTypes.Text;
  text: EntryFieldTypes.Text;
};

export type GeneralInsuranceInfoContentSkeleton = {
  contentTypeId: 'generalInsuranceInfo';
  fields: {
    title?: EntryFieldTypes.Text;
    body: EntryFieldTypes.Text;
  };
};

export type GeneralInsuranceInfoContentSkeletonEntry =
  | Entry<
      GeneralInsuranceInfoContentSkeleton,
      'WITHOUT_UNRESOLVABLE_LINKS',
      string
    >
  | undefined;

export type GeneralInsuranceInfoModalContentSkeletonEntry = Entry<
  GeneralInsuranceInfoModalContentSkeleton,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;
export type GeneralInsuranceInfoTextContentSkeleton = {
  category_code: EntryFieldTypes.Text;
  category: EntryFieldTypes.Text;
  text: EntryFieldTypes.Text;
};

export type GeneralInsuranceInfoModalFields = {
  title: EntryFieldTypes.Text;
  texts: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<GeneralInsuranceInfoContentSkeleton>
  >;
};

export type GeneralInsuranceInfoModalContentSkeleton = {
  contentTypeId: 'generalInsuranceInfoModal';
  fields: GeneralInsuranceInfoModalFields;
};

export type SejfChatSectionsContentSkeleton = {
  contentTypeId: 'chatSections';
  fields: SejfChatSections;
};

export type SejfChatSections = {
  sections: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<SejfChatSectionContentSkeleton>
  >;
};

export type SejfChatSectionContentSkeleton = {
  contentTypeId: 'chatSection';
  fields: SejfChatSection;
};
export type SejfChatSection = {
  buttonText?: EntryFieldTypes.Text;
  message: EntryFieldTypes.Text;
};

export type ChatSectionsContent = {
  cancelledInsuranceMessage: EntryFieldTypes.Text;
  draftInsuranceMessage: EntryFieldTypes.Text;
  unverifiedEmailMessage: EntryFieldTypes.Text;
  unverifiedEmailButtonText: EntryFieldTypes.Text;
  paymentMissingMessage: EntryFieldTypes.Text;
  paymentMissingButtonText: EntryFieldTypes.Text;
  activeClaimMessage: EntryFieldTypes.Text;
  activeClaimButtonText: EntryFieldTypes.Text;
  defaultMessage: EntryFieldTypes.Text;
  defaultMessage2: EntryFieldTypes.Text;
  defaultMessage3: EntryFieldTypes.Text;
};

export type ChatSectionsContentSkeleton = {
  fields: ChatSectionsContent;
  contentTypeId: 'chatSections';
};

export type PriceSuggestionBlockContentSkeleton = {
  contentTypeId: 'priceSuggestionBlock';
  fields: PriceSuggestionBlockContent;
};

export type PriceSuggestionBlockContent = {
  title: EntryFieldTypes.Text;
  description: EntryFieldTypes.Text;
  edit: EntryFieldTypes.Text;
  yourPrice: EntryFieldTypes.Text;
};

export type IdentificationScreenContent = {
  chatMessages: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  links: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<DropdownContentSkeleton>
  >;
};

export type IdentificationScreenContentSkeleton = {
  contentTypeId: 'identificationScreen';
  fields: IdentificationScreenContent;
};

export type GenereicTextSectionContent = {
  title: EntryFieldTypes.Text;
  subtitle: EntryFieldTypes.Text;
  body: EntryFieldTypes.Text;
};

export type GenereicTextSectionContentSkeleton = {
  contentTypeId: 'genericTextSection';
  fields: GenereicTextSectionContent;
};
export type ImportantDocumentBlockContent = {
  title: EntryFieldTypes.Text;
  prePurchaseInformationText: EntryFieldTypes.Text;
  prePurchaseInformationUrl: EntryFieldTypes.Text;
  productFactSheetText: EntryFieldTypes.Text;
  productFactSheetUrl: EntryFieldTypes.Text;
  termsText: EntryFieldTypes.Text;
  termsUrl: EntryFieldTypes.Text;
};

export type ImportantDocumentBlockContentSkeleton = {
  contentTypeId: 'importantDocumentBlock';
  fields: ImportantDocumentBlockContent;
};

export type GenericMarkdownPageContentSkeleton = {
  contentTypeId: 'markdownPage';
  fields: GenericMarkdownPageContent;
};

export type GenericMarkdownPageContent = {
  textContent: EntryFieldTypes.RichText;
};

export type ImageContent = {
  title: EntryFieldTypes.Text;
  file: {
    url: string;
  };
};

export type ImageContentSkeleton = {
  contentTypeId: 'image';
  fields: ImageContent;
};

export type SectionsContent = {
  internalName: EntryFieldTypes.Text;
  title: EntryFieldTypes.Text;
  description: EntryFieldTypes.Text;
  containerLayout: EntryFieldTypes.Boolean;
  image: ImageContentSkeleton;
  link: EntryFieldTypes.Text;
  linkLabel: EntryFieldTypes.Text;
};

export type SectionsContentSkeleton = {
  contentTypeId: 'sections';
  fields: SectionsContent;
};

export type ReferralPageContent = {
  internalName: EntryFieldTypes.Text;
  title: EntryFieldTypes.Text;
  slug: EntryFieldTypes.Text;
  sections: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<SectionsContentSkeleton>
  >;
};

export type ReferralPageContentSkeleton = {
  contentTypeId: 'referralPage';
  fields: ReferralPageContent;
};
