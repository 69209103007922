import { useEffect, useState } from 'react';

import useWindow from './useWindow';

const useLocation = () => {
  const { hasWindow } = useWindow();
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    if (hasWindow) {
      setLocation(window.location);
    }
  }, [hasWindow]);

  return { location };
};

export default useLocation;
