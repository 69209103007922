import { Icon } from '@components/index';

import { useLandingPageTranslation } from '../translation';

export const IosButton = () => {
  const t = useLandingPageTranslation();

  return (
    <div
      onClick={goToAppStore}
      className="flex h-16 items-center justify-between rounded-[56px] border border-white p-5"
    >
      <div className="flex items-center">
        <Icon name="apple-icon" className="rounded-full text-white" />
        <div className="text-l mx-2 font-semibold text-white">
          {t('FOR_IOS')}
        </div>
      </div>
      <Icon name="arrow-forward" className="rounded-full text-white invert" />
    </div>
  );
};

export const AndroidButton = () => {
  const t = useLandingPageTranslation();

  return (
    <div
      onClick={goToPlayStore}
      className="flex h-16 items-center justify-between rounded-[56px] border border-white p-5"
    >
      <div className="flex items-center">
        <Icon name="google-icon" className="rounded-full text-white" />
        <div className="text-l mx-2 font-semibold text-white">
          {t('FOR_ANDROID')}
        </div>
      </div>
      <Icon name="arrow-forward" className="rounded-full text-white invert" />
    </div>
  );
};

const openUrl = (url: string) => window.open(url, '_blank');

const goToPlayStore = () =>
  openUrl('https://play.google.com/store/apps/details?id=nu.sejfa.android');

const goToAppStore = () =>
  openUrl('https://apps.apple.com/us/app/sejfa/id6449179160');
