import React from 'react';

import {
  Button,
  Divider,
  IconType,
  TextInput,
  TextWithLabel
} from '@components/index';
import { UserInfo } from '@core/types';
import { capitalizeAll, isEmail, isPhoneNumber } from '@lib/utils';

import { useOnboardingTranslation } from '../../features/onboarding/translation';
import { useProfilePageTranslation } from '../../features/profile/translation';
import InformationBlock from '../InformationBlock/InformationBlock';

type Props = {
  userInfo: Partial<UserInfo>;
  onChange: (changedValue: { key: keyof UserInfo; value: string }) => void;
  className?: string;
  onSubmitClick?: () => void;
  onEditClick?: () => void;
  isSubmitButtonDisabled?: boolean;
  isEditingDisabled: boolean;
};

export type RowData = {
  label: string;
  text: string | number;
  key: keyof UserInfo;
  dataType: 'text' | 'input';
  type: 'email' | 'text' | 'tel' | 'number';
  isValid?: boolean;
  errorMessage?: string;
  mandatory?: boolean;
  icon?: IconType;
  helperText?: string;
};

const ContactInfoBlock = ({
  userInfo,
  className,
  onChange,
  onSubmitClick,
  onEditClick,
  isSubmitButtonDisabled,
  isEditingDisabled
}: Props) => {
  const t = useOnboardingTranslation();
  const pt = useProfilePageTranslation();

  const rows: RowData[] = [
    {
      label: t('CONTACT_INFO.FIRST_NAME'),
      text: capitalizeAll(userInfo?.givenName ?? ''),
      key: 'givenName',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.LAST_NAME'),
      text: capitalizeAll(userInfo?.lastName ?? ''),
      key: 'lastName',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.PHONE_NUMBER'),
      text: userInfo.phoneNumber ?? '',
      dataType: 'input',
      key: 'phoneNumber',
      type: 'tel',
      isValid:
        !userInfo.phoneNumber || isPhoneNumber.test(userInfo.phoneNumber ?? ''),
      errorMessage: t('VALIDATION.PHONE.MUST_BE_PHONE_NUMBER'),
      icon: 'edit'
    },
    {
      label: t('CONTACT_INFO.EMAIL'),
      text: userInfo.email ?? '',
      key: 'email',
      dataType: 'input',
      type: 'email',
      isValid: isEmail.test(userInfo.email ?? ''),
      errorMessage: t('VALIDATION.EMAIL.MUST_BE_EMAIL'),
      mandatory: true,
      icon: 'edit',
      helperText: userInfo.isEmailVerified ? pt('EMAIL_VERIFIED') : ''
    }
  ];

  return (
    <InformationBlock
      title={t('PRICE_SUGGESTION_SCREEN.CONTACT_DETAILS')}
      titleClassName="md:text-[40px]"
      containerClassName="gap-0"
      className={className}
    >
      {rows.map((row) =>
        row.key === 'email' || row.key === 'phoneNumber' ? (
          <label key={row.key} className="mt-8">
            <TextInput
              disabled={isEditingDisabled}
              containerClassName="pb-0 mt-4"
              floatingLabel={row.label}
              value={row.text.toString()}
              type={row.type}
              onChange={(e) =>
                onChange({ key: row.key, value: e.target.value })
              }
              className="text-2xl"
              floatingLabelClassName="text-xl"
              mandatory={row.mandatory}
              icon={row.icon}
              onIconClick={onEditClick}
            />
            <Divider className="bg-black" />
            {!row.isValid && (
              <span className="border-l-errorBackground text-sm text-error ">
                {row.errorMessage}
              </span>
            )}
            {!!row.helperText && (
              <span className="mt-2 text-sm  text-textSecondary">
                {row.helperText}
              </span>
            )}
          </label>
        ) : (
          <React.Fragment key={row.key}>
            <TextWithLabel label={row.label} text={row.text.toString()} />
            <Divider />
          </React.Fragment>
        )
      )}
      {typeof onSubmitClick === 'function' && (
        <Button
          variant="secondary"
          isLoading={isSubmitButtonDisabled}
          className="mt-6 h-[48px] md:w-full"
          onClick={onSubmitClick}
          disabled={isSubmitButtonDisabled}
        >
          {isSubmitButtonDisabled ? t('ACTIONS.UPDATING') : t('ACTIONS.UPDATE')}
        </Button>
      )}
    </InformationBlock>
  );
};

export default ContactInfoBlock;
