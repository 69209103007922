import { createRequest } from './createRequest';
import { ApiDefinition, DefaultError, RequestOptions } from './types';

/*
  Factory for request-creators given specified API definiton (baseUrl, accepted paths)
*/
export const createRequestCreatorForApi =
  <Api extends ApiDefinition, ErrorType = DefaultError>({
    baseUrl,
    headers,
    axiosClient
  }: Api) =>
  <Result, Parameters = undefined>(
    options: Omit<
      RequestOptions<Api['baseUrl'], Api['paths'][number]>,
      'baseUrl'
    >
  ) =>
    createRequest<
      Api['baseUrl'],
      Api['paths'][number],
      Result,
      Parameters,
      ErrorType
    >({
      ...options,
      baseUrl,
      headers,
      axiosClient
    });
