import { useFeature } from '@core/feature-toggles';

type UsePurchaseLimitsResult = {
  purchaseIsForbidden: boolean;
};

export const usePurchaseLimits = (): UsePurchaseLimitsResult => {
  const { enabled: purchaseForbiddenEnabled, value: purchaseForbidden } =
    useFeature('purchase-forbidden');

  const purchaseIsForbidden =
    purchaseForbiddenEnabled && purchaseForbidden !== null;

  return {
    purchaseIsForbidden
  };
};
