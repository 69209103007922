import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, frequency: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!frequency && frequency !== 0) {
      return;
    }
    const interval = setInterval(savedCallback.current, frequency);

    return () => {
      clearInterval(interval);
    };
  }, [callback, frequency]);
};
