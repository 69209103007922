import React from 'react';

import EarlyAdopterImg from '@assets/images/early-adopter.webp';
import { useEarlyAdopter } from '@core/content-management';
import { NextPageWithLayout } from '@pages/_app';

import { RichTextPage } from '../components';
import EarlyAdopterForm from '../components/EarlyAdopterForm';
import Footer from '../components/Footer';

const EarlyAdopterPage: NextPageWithLayout = () => {
  const { data } = useEarlyAdopter();

  const content = data?.fields?.textContent;

  return (
    <>
      <div className="min-w-screen min-h-screen flex-1 rounded-b-[32px] bg-white md:min-h-[900px]">
        {content && (
          <RichTextPage src={EarlyAdopterImg} Text={content ?? undefined}>
            <EarlyAdopterForm />
          </RichTextPage>
        )}
      </div>
      <div className="bg-black pb-12 md:p-20">
        <Footer />
      </div>
    </>
  );
};

export default EarlyAdopterPage;
