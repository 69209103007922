import { logger } from '@lib/logger';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'pageview',
      page: url
    });
  } else {
    logger.log('dataLayer not defined for pageView: ', {
      url
    });
  }
};
