import { useState, useEffect } from 'react';

import { GetTemplateResponse, queries } from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { useWindow } from '@lib/utils';

export const useTemplateData = (): {
  data: GetTemplateResponse;
  isLoading: boolean;
} => {
  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');
  const [productId, setProductId] = useState<string | undefined>();
  const { useGetTemplate } = queries.quotes;
  const { useGetProducts } = queries.products;
  const { hasWindow } = useWindow();

  const onError = (err: unknown) => {
    addMessage(
      {
        message: tError('UNEXPECTED_ERROR'),
        payload: err
      },
      true
    );
  };
  const { data, isRefetching, isInitialLoading } = useGetTemplate({
    parameters: { productId: productId as string },
    options: {
      enabled: !!productId,
      onError
    }
  });

  const storageToken = hasWindow ? storage().accessToken : undefined;

  const { data: productData } = useGetProducts({
    options: { enabled: !!storageToken }
  });

  useEffect(() => {
    if (productData?.result?.productList[0].productId) {
      setProductId(productData?.result.productList[0].productId);
    }
  }, [productData?.result]);
  const templateData = data as GetTemplateResponse;
  return { data: templateData, isLoading: isRefetching || isInitialLoading };
};
