import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from '@tanstack/react-query';

import { createApiClient } from './createApiClient';
import { createClient } from './createClient';
import { createRequestCreatorForApi } from './createRequestCreatorForApi';
import { type DefaultError } from './types';
export { axiosWithAuth } from './interceptors';

export {
  createRequestCreatorForApi,
  createClient,
  createApiClient,
  type DefaultError,
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters
};
