import { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { useTranslation } from '@core/translation';

type SkipButtonProps = {
  className?: string;
  onClick?: () => void;
  text?: string;
};

const SkipButton: FC<SkipButtonProps> = ({ className, onClick, text }) => {
  const t = useTranslation('COMMON');

  const classes = twMerge(
    'md:mt-9 mt-3 flex flex-row items-center rounded-xl bg-white p-2',
    onClick ? 'visible' : 'invisible',
    className
  );

  return (
    <button onClick={onClick} className={classes}>
      <span className="text-l mr-2 font-bold">
        {text ?? t('SKIP_AND_CLOSE')}
      </span>
    </button>
  );
};

export default SkipButton;
