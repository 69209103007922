import { PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { useTranslation } from '@core/translation';

import { Block, Button, IconButton, Modal } from '..';

type Props = {
  title: string;
  description: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  onClose?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  containerClassName?: string;
} & PropsWithChildren;

const ActionModal = ({
  title,
  description,
  onSecondaryClick,
  onPrimaryClick,
  primaryButtonText,
  secondaryButtonText,
  onClose,
  children,
  containerClassName
}: Props) => {
  const t = useTranslation();
  return (
    <Modal className="z-50">
      <div className={twMerge('relative w-full md:w-1/3', containerClassName)}>
        <Block
          className="flex flex-col justify-between bg-white p-4 pt-6 md:p-8 md:pb-12"
          title="Title"
        >
          {children ? (
            children
          ) : (
            <>
              <div>
                <h5
                  className={twMerge(
                    'overflow-hidden break-normal text-[40px] font-bold text-black md:text-[48px]'
                  )}
                >
                  {title}
                </h5>
                <p
                  className={twMerge(
                    'overflow-hidden break-normal text-[16px] font-semibold text-black md:text-[24px]'
                  )}
                >
                  {description}
                </p>
              </div>
              <div className="mt-8 flex flex-col-reverse gap-2 md:flex-row">
                <Button onClick={onPrimaryClick} className="order-last">
                  {primaryButtonText ?? t('COMMON.CONTINUE')}
                </Button>
                <Button onClick={onSecondaryClick} variant="secondary">
                  {secondaryButtonText ?? t('COMMON.CANCEL')}
                </Button>
              </div>
            </>
          )}
        </Block>
        {!!onClose && (
          <IconButton
            name="close"
            onClick={onClose}
            className="absolute right-4 top-4 bg-primary"
          />
        )}
      </div>
    </Modal>
  );
};

export default ActionModal;
