import React from 'react';

import { Divider, TextWithLabel } from '@components/index';
import { UserInfo } from '@core/types';
import { date } from '@lib/date';

import InformationBlock from '../../../components/InformationBlock/InformationBlock';
import { useOnboardingTranslation } from '../translation';

type Props = {
  userInfo: UserInfo;
  firstName?: string;
  lastName?: string;
  email?: string;
  onChange: (changedValue: { key: keyof UserInfo; value: string }) => void;
};

export type RowData = {
  label: string;
  text: string | number;
  key: keyof UserInfo;
  dataType: 'text' | 'input';
  type: 'email' | 'text' | 'tel' | 'number';
  isValid?: boolean;
  errorMessage?: string;
};

const SummaryBlock = ({ userInfo }: Props) => {
  const t = useOnboardingTranslation();

  const birthDate = userInfo?.birthDate
    ? date.format(new Date(userInfo.birthDate), 'yyyy-MM-dd')
    : '';

  const rows: RowData[] = [
    {
      label: t('CONTACT_INFO.BIRTH_DATE'),
      text: birthDate,
      key: 'birthDate',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.ADDRESS'),
      text: userInfo.address ?? '',
      key: 'address',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.POSTAL_CODE'),
      text: userInfo.postalCode,
      dataType: 'text',
      key: 'postalCode',
      type: 'number'
    },
    {
      label: t('CONTACT_INFO.RESIDENTS'),
      text: userInfo.residents,
      dataType: 'text',
      key: 'residents',
      type: 'number'
    }
  ];

  return (
    <InformationBlock
      title={t('PRICE_SUGGESTION_SCREEN.SUMMARY')}
      titleClassName="md:text-[40px]"
      containerClassName="gap-0"
    >
      {rows.map((row) => (
        <React.Fragment key={row.key}>
          <TextWithLabel label={row.label} text={row.text.toString()} />
          <Divider />
        </React.Fragment>
      ))}
    </InformationBlock>
  );
};

export default SummaryBlock;
