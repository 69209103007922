import { ToastType } from '@components/index';
import { createStore } from '@lib/state-management';

export type ToastMessage = {
  message: string;
  toastType?: ToastType;
  durationMs?: number;
  payload?: unknown;
};

type MessageStoreState = { messages: ToastMessage[] };

const initialState: MessageStoreState = {
  messages: []
};

const updateFunctions = {
  add: (toastMessage: ToastMessage) => (state: MessageStoreState) => ({
    messages: [...state.messages, toastMessage]
  }),
  remove: (toastMessage: ToastMessage) => (state: MessageStoreState) => ({
    messages: state.messages.filter(
      (message) => message.message !== toastMessage.message
    )
  })
};

export const {
  useSelectFromStore: useSelectFromMessageStore,
  updateStore: updateMessageStore,
  resetStore: resetMessageStore
} = createStore(initialState, updateFunctions);
