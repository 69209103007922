import { FC, useEffect, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { Button } from '@components/index';
import { ContentType, Message } from '@core/types';

import ChatMessage from './ChatMessage';
import LoadingMessage from './LoadingMessage';

type ChatConversationProps = {
  className?: string;
  messages: Message[];
};

const classNameByMessageType: Record<Message['type'], string> = {
  recipient: 'justify-items-start',
  sender: 'justify-items-end'
};

const classNameByContentType: Record<Message['contentType'], string> = {
  action: 'justify-items-end',
  image: 'justify-items-end',
  link: 'justify-items-end',
  text: 'justify-items-start',
  video: 'justify-items-end'
};

const ChatConversation: FC<ChatConversationProps> = ({
  className,
  messages
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage) {
      handleScrollTo(lastMessage.id);
    }
  }, [messages]);

  const handleOpenUrl = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const containerClasses = twMerge(
    'flex flex-1 flex-col justify-end md:justify-start pb-20 md:pb-20 overflow-y-auto no-scrollbar px-4 h-full',
    className
  );

  return (
    <div className={containerClasses} ref={containerRef}>
      {messages.length === 0 && <LoadingMessage />}
      {messages.map((message, index) => {
        const previousMessageType = messages?.[index - 1]?.type;

        const chatClasses = twMerge(
          'mt-0',
          previousMessageType === message.type ? 'mt-[2px]' : 'mt-4'
        );

        const messageClassName = twMerge(
          'grid',
          message.contentType === 'action'
            ? classNameByContentType[message.contentType]
            : classNameByMessageType[message.type],
          chatClasses
        );

        if (message.link) {
          message.action = () => handleOpenUrl(message.link);
        }
        const CONTENT_TYPE_COMPONENT: Record<
          ContentType,
          JSX.Element | undefined
        > = {
          action: (
            <Button
              id={message.id}
              hugged
              variant="secondary"
              className="mt-0 w-auto px-6 py-3"
              onClick={message?.action}
              icon={message?.link ? 'open' : undefined}
              iconClassName="w-6 h-6"
            >
              {message.message}
            </Button>
          ),
          text: message.message ? (
            <ChatMessage key={message.id} message={message} />
          ) : undefined,
          link: undefined,
          image: undefined,
          video: undefined
        } as const;

        return (
          <div key={message.id} className={messageClassName}>
            {CONTENT_TYPE_COMPONENT[message.contentType]}
          </div>
        );
      })}
    </div>
  );
};

export default ChatConversation;
