import { ProductDataResponse } from './types';
import { createRequest } from '../../api-definition';

export const getProductList = createRequest<ProductDataResponse, void>({
  path: 'Products/GetProductList',
  method: 'GET'
});

export const getProducts = createRequest<ProductDataResponse, void>({
  path: 'Products',
  method: 'GET'
});
