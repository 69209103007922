import { useEffect, useState } from 'react';

import { useFeature } from '@core/feature-toggles';

type UseSystemMessageResult = {
  message: string | null;
  removeMessage: () => void;
};

export const useSystemMessage = (): UseSystemMessageResult => {
  const { enabled: systemMessageEnabled, value: systemMessageValue } =
    useFeature('system-message');
  const [systemMessage, setSystemMessage] = useState<string | null>(null);
  useEffect(() => {
    if (systemMessageValue && systemMessageEnabled) {
      setSystemMessage(systemMessageValue);
    }
  }, [systemMessageValue, systemMessageEnabled]);

  return {
    message: systemMessage,
    removeMessage: () => setSystemMessage(null)
  };
};
