import { checkEmailVerificationStatus } from '../requests';
import { createQuery } from '../store';

const useCheckEmailVerificationStatus = createQuery(
  'checkEmailVerificationStatus',
  checkEmailVerificationStatus
);

const common = {
  useCheckEmailVerificationStatus
};

export default common;
