export const formatExpiryDate = (expiry: string): string => {
  if (expiry.length !== 4) {
    throw new Error('Invalid expiry date format. Must be 4 digits.');
  }

  const month = expiry.slice(0, 2);
  const year = expiry.slice(2, 4);

  return `${month}/${year}`;
};
