import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormResetField
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { IconButton } from '@components/index';
import { useMessaging } from '@core/messaging';

import { OnboardingChatForm } from '../types';

type ChatInputProps = {
  className?: string;
  disabled?: boolean;
  onSend: (value: string) => void;
  editable?: boolean;
  placeholder?: string;
  valueSuffix?: string;
  hasFocus?: boolean;
  onChangeText?: (value: string) => void;
  error?: string;
  value?: string;
  isLoading?: boolean;
  trimOnSend?: boolean;
  type?: keyof OnboardingChatForm;
  iconButtonClasses?: string;
  formClasses?: string;
  keyboardType?: 'tel' | 'email' | 'text';
};

type Props<TFieldValue extends FieldValues> = ChatInputProps & {
  resetField?: UseFormResetField<TFieldValue>;
  field?: ControllerRenderProps<TFieldValue, FieldPath<TFieldValue>>;
};

const ChatInput = <T extends FieldValues>({
  className,
  disabled = false,
  valueSuffix,
  hasFocus = false,
  error,
  onSend,
  resetField,
  field,
  placeholder,
  iconButtonClasses,
  formClasses,
  isLoading,
  keyboardType = 'text'
}: Props<T>) => {
  const [value, setValue] = useState<string>('');
  const { addMessage } = useMessaging();

  const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit = useCallback(() => {
    if (!value && !field?.value) {
      return;
    }
    if (error) {
      addMessage({ message: error, durationMs: 3000 });
      return;
    }

    onSend(field?.value ?? value);
    if (field) {
      resetField?.(field.name);
    }
    setValue('');
  }, [value, field, error, onSend, addMessage, resetField]);

  const focusOnInput = () => inputRef.current?.focus();
  const blurInput = () => inputRef.current?.blur();

  useEffect(() => {
    if (hasFocus) {
      focusOnInput();
    }
  }, [hasFocus]);

  /* Dynamic change of input type is sometimes not supported. Therefore, we blur and re-focus after changing input type. */
  useEffect(() => {
    blurInput();

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.type = keyboardType;
        focusOnInput();
      }
    }, 100);
  }, [keyboardType]);

  const handleValidation = (ev: ChangeEvent<HTMLInputElement>) => {
    field?.onChange(ev);
    handleChangeAndSize(ev, true);
  };

  const handleChangeAndSize = (
    ev: ChangeEvent<HTMLInputElement>,
    hasValidation = false
  ) => {
    const target = ev.target;
    if (!hasValidation) {
      setValue(target.value);
    }
  };

  const inputClasses = twMerge(
    'text-l mr-0 ml-8 text-black outline-none md:text-xl bg-white',
    value && 'text-left'
  );

  const inputContainerClasses = twMerge(
    'flex h-16 w-full flex-row items-center justify-between rounded-full border-2 border-black bg-white pr-1 pl-4',
    error && 'border-black', // TODO: decide if we want red border when we have error
    className
  );

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!disabled) {
            onSubmit();
          }
        }}
        className={twMerge('h-[64px]', formClasses)}
      >
        <div className={inputContainerClasses} onClick={focusOnInput}>
          <div className="flex w-10/12 flex-row ">
            {field ? (
              <input
                {...field}
                ref={inputRef}
                onChange={(event) => handleValidation(event)}
                className={inputClasses}
                value={field?.value ?? ''}
                disabled={disabled}
                type={keyboardType}
                style={{
                  width: field?.value?.length
                    ? `${field?.value?.length * 12}px`
                    : '10px'
                }}
              />
            ) : (
              <input
                ref={inputRef}
                className="text-l w-full font-medium text-black outline-none"
                disabled={disabled}
                value={value}
                type={keyboardType}
                onChange={handleChangeAndSize}
                placeholder={placeholder}
              />
            )}
            {!!valueSuffix && !disabled && (
              <span className="text-l self-end border-l-errorBackground text-grey ">
                {valueSuffix}
              </span>
            )}
          </div>
          <div>
            <IconButton
              name="arrow-up"
              disabled={disabled}
              isLoading={isLoading}
              onClick={onSubmit}
              activityIndicatorContainer="ml-0"
              className={twMerge(
                'bg-primary',
                disabled && 'bg-disabled',
                iconButtonClasses
              )}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ChatInput;
