import React from 'react';

import NextLink from 'next/link';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  link: string;
  title: string;
  target?: '_blank' | '_self' | undefined;
};

const Link = ({ link, title, className, target = '_blank' }: Props) => {
  const classes = twMerge(
    'font-medium hover:underline text-grey text-sm',
    className
  );

  return (
    <NextLink target={target} className={classes} href={link}>
      {title}
    </NextLink>
  );
};

export default Link;
