import storage from '@core/storage';
import { OnboardingData } from '@core/types';
import { createStore } from '@lib/state-management';

const getInitialState = (): OnboardingData => {
  const sessionData = storage().onboarding;

  const state = {
    ...initialState,
    ...(sessionData ? JSON.parse(sessionData) : {})
  };

  return state;
};

const initialState: OnboardingData = {
  step: 1,
  totalSteps: 3,
  progressionStep: 0,
  totalProgressionSteps: 5
};

const updateFunctions = {
  updateProgress:
    (progress: Partial<OnboardingData>) => (state: OnboardingData) => {
      const { saveOnboardingStore } = storage();
      saveOnboardingStore({ ...state, ...progress });
      return progress;
    },
  resetOnboarding: () => () => {
    return initialState;
  }
};

export const {
  useSelectFromStore: useSelectFromOnboardingStore,
  updateStore: updateOnboardingStore,
  resetStore: resetOnboardingStore
} = createStore(getInitialState(), updateFunctions);
