import {
  CalculateQuoteRequest,
  mutations,
  useErrorCodeTranslation
} from '@core/api';
import { useMessaging } from '@core/messaging';
import { logger } from '@lib/logger';

const useCreateQuote = () => {
  const { useCreateQuote: useQuote } = mutations.quotes;
  const { addMessage } = useMessaging();
  const { mutateAsync, isLoading } = useQuote({
    onError: (error) => {
      const message = getErrorTranslation(error?.data?.code);
      logger.error(message, error);
      addMessage({
        message,
        payload: error
      });
    }
  });
  const { getErrorTranslation } = useErrorCodeTranslation();

  const createQuote = async (createQuoteBody: CalculateQuoteRequest) => {
    const newQuote = await mutateAsync(createQuoteBody);
    const quoteId = newQuote?.result?.quoteId;

    if (quoteId) {
      return quoteId;
    }
  };

  return { createQuote, isLoading };
};

export default useCreateQuote;
