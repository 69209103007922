import axios from 'axios';
import { getCookie } from 'cookies-next';
import { NextApiRequest, NextApiResponse } from 'next';

import env from '@core/env-config';

export const createApiClient = (req: NextApiRequest, res: NextApiResponse) => {
  const authKey = getCookie('authKey', { req, res });
  const accessToken = getCookie('accessToken', { req, res });

  return axios.create({
    baseURL: env.INSURANCE_IN_A_BOX_URL,
    headers: {
      apiKey: env.INSURANCE_IN_A_BOX_KEY,
      authKey: authKey ?? '',
      Authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  });
};
