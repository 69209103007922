import { logger } from '@lib/logger';

import { TrackingTransport } from './types';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

const trackGoogleTagManagerEvent: TrackingTransport['trackEvent'] = async (
  name,
  params
) => {
  try {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: name,
        params
      });
    } else {
      logger.log('Google Tag Manager dataLayer not defined for event: ', {
        name
      });
    }
    // track event
    // track(name, params || {});
  } catch (error) {
    logger.error('Google Tag Manager logEvent error', error, {
      name,
      params
    });
  }
};

const GoogleTagManagerTransport: TrackingTransport = {
  name: 'GoogleTagManager',
  trackEvent: trackGoogleTagManagerEvent
};

export default GoogleTagManagerTransport;
