import React from 'react';

import Image from 'next/image';

import AccordLogo from '@assets/images/accord-logo.svg';
import Background from '@assets/images/accord_hero.webp';
import Heart from '@assets/images/safety-by-cilia-heart.svg';
import SejfaLogo from '@assets/sejfa-logo.svg';

const Hero = () => {
  return (
    <div className="p-4 md:p-10">
      <div className="z-2 mb-6 flex flex-col items-center justify-center pt-6 md:mb-10 md:flex-row md:pt-0">
        <Image
          src={SejfaLogo}
          alt="Sejfa logo"
          priority
          className="mb-10 ml-5 h-[79px] w-[167px] md:mb-0 md:ml-0 md:h-[114px] md:w-[266px]"
        />
        <Image
          src={Heart}
          alt="Heart"
          priority
          className="md:mr- mb-8 h-[55px] w-[55px] md:mx-16 md:mb-0 md:h-[87px] md:w-[87px]"
        />
        <Image
          src={AccordLogo}
          alt="Accord logo"
          priority
          className="md:h-[104 px] mr-4 h-[66px] w-[242px] md:mb-6 md:mr-0 md:h-[104px] md:w-[383px]"
        />
      </div>
      <Image
        src={Background}
        alt="Accord background"
        priority
        className="h-[371px] w-full rounded-[16px] object-cover object-top md:h-auto"
      />
    </div>
  );
};

export default Hero;
