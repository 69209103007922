import { useState } from 'react';

import { Button, Checkbox, TextInput } from '@components/index';
import { useEarlyAdopterOffer } from '@core/api';
import { useMessaging } from '@core/messaging';
import { useTranslation } from '@core/translation';
import { logger } from '@lib/logger';
import { delay, isEmail } from '@lib/utils';

import SelectArea from './SelectArea';
import YourIntegrityLink from './YourIntegrityLink';
import { useLandingPageNavigation } from '../navigation';

const EarlyAdopterForm = () => {
  const t = useTranslation('EARLY_ADOPTER_PAGE');
  const { navigateToRoot } = useLandingPageNavigation();
  const { addMessage } = useMessaging();
  const { mutateAsync: sendEarlyAdopter, isLoading } = useEarlyAdopterOffer({
    onError: (error) => {
      logger.error('Send early adopter failed:', error);
    },
    onSuccess: () => {
      addMessage({
        toastType: 'success',
        message: t('EARLY_ADOPTER_SUCCESS')
      });

      delay(3500).then(() => {
        navigateToRoot();
      });
    }
  });
  const ct = useTranslation('COMMON');
  const [email, setEmail] = useState('');
  const [area, setArea] = useState('');
  const [customArea, setCustomArea] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState(0);
  const [userConsent, setUserConsent] = useState(false);

  const handleSubmit = () => {
    sendEarlyAdopter({
      email,
      area: area === t('OTHER') ? customArea : area,
      name,
      age
    });
  };
  const isValidEmail = isEmail.test(email);

  const handleSetArea = (area: string) => {
    setCustomArea('');
    setArea(area);
  };

  return (
    <div className="column my-4 flex max-w-full flex-col justify-between rounded-[32px] bg-white p-0">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <h5 className="mt-6 font-semibold">{t('NAME')}</h5>
        <TextInput
          id="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          className="w-full border-b-2 border-black bg-transparent pl-0"
          placeholder={t('ENTER_NAME')}
        />
        <h5 className="mt-6 font-semibold">{t('AGE')}</h5>
        <TextInput
          type="number"
          id="age"
          onChange={(e) => setAge(Number(e.target.value))}
          className="w-full border-b-2 border-black bg-transparent pl-0"
          placeholder={t('ENTER_AGE')}
        />
        <h5 className="mt-6 font-semibold">{ct('EMAIL')}</h5>
        <TextInput
          type="email"
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          className="w-full border-b-2 border-black bg-transparent pl-0"
          placeholder={t('ENTER_EMAIL')}
        />
        <SelectArea onChange={handleSetArea} />
        {area === t('OTHER') && (
          <>
            <h5 className="mt-6 font-semibold">{t('AREA')}</h5>
            <TextInput
              id="name"
              type="text"
              onChange={(e) => setCustomArea(e.target.value)}
              className="w-full border-b-2 border-black bg-transparent pl-0"
              placeholder={t('ENTER_AREA')}
            />
          </>
        )}
        <Checkbox text={t('EARLY_ADOPTER_CONSENT')} onClick={setUserConsent} />
        <YourIntegrityLink />

        <Button
          isLoading={isLoading}
          type="submit"
          className="mb-2 mt-[100px] h-[72px] w-full bg-text text-white"
          disabled={
            !userConsent || !isValidEmail || !area || !name || !age || isLoading
          }
        >
          {ct('SEND')}
        </Button>
      </form>
    </div>
  );
};

export default EarlyAdopterForm;
