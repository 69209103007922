import React, { HTMLAttributes } from 'react';

import { cn } from '@lib/utils';

type Props = HTMLAttributes<HTMLDivElement>;

const BlockContainer = ({ children, className, ...rest }: Props) => {
  return (
    <div
      className={cn(
        'h-full items-center space-y-0.5 rounded-2xl bg-white p-8 md:rounded-[32px]',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BlockContainer;
