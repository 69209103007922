import { useState } from 'react';

import { UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { delay, getRandomNumberInRange, useTimeout } from '@lib/utils';

import { createMutation } from './mutation';
import { QueryKeyDefinition, UseMutationOptions } from './types';

type CreateMockMutationOptions = {
  minLoadingDuration?: number;
  maxLoadingDuration?: number;
};

const defaultMockOptions = {
  minLoadingDuration: 500,
  maxLoadingDuration: 2000
};

export const createMockMutation = <Variables, Result, ErrorType>(
  mutation: ReturnType<
    typeof createMutation<
      QueryKeyDefinition<Variables>,
      Result,
      Variables,
      ErrorType
    >
  >,
  mockData: Result,
  createMockOptions?: CreateMockMutationOptions
) => {
  const useMockMutation = (
    options: Omit<
      UseMutationOptions<Result, Variables, ErrorType>,
      'request'
    > = {}
  ): UseMutationResult<Result, ErrorType, Variables, unknown> => {
    const queryClient = useQueryClient();
    const [result, setResult] = useState<
      UseMutationResult<Result, ErrorType, Variables, unknown>
    >({
      /* variables included for matching original definition */

      mutate: (variables: Variables) => {
        options.onSuccess?.(mockData, variables, queryClient);
      },
      mutateAsync: async (variables: Variables) => {
        options.onSuccess?.(mockData, variables, queryClient);
        await delay(1);
        return mockData;
      },
      data: undefined,
      error: null,
      isError: false,
      isIdle: false,
      isLoading: true,
      isSuccess: false,
      status: 'loading',
      context: undefined,
      failureCount: 0,
      failureReason: null,
      isPaused: false,
      variables: undefined,
      reset: () => null
    });

    const {
      minLoadingDuration = defaultMockOptions.minLoadingDuration,
      maxLoadingDuration = defaultMockOptions.maxLoadingDuration
    } = createMockOptions ?? {};

    const loadingDuration = getRandomNumberInRange(
      minLoadingDuration,
      maxLoadingDuration
    );

    useTimeout(() => {
      setResult((prevResult) => ({
        ...prevResult,
        isLoading: false,
        data: mockData,
        error: null,
        isError: false,
        isSuccess: true,
        status: 'success',
        isIdle: false
      }));
    }, loadingDuration);

    return result;
  };

  return useMockMutation;
};
