import { UseFormResetField } from 'react-hook-form';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions
} from '@lib/validation';

import ChatInput from './ChatInput';
import { PriceSuggestionFormValues } from '../hooks/usePriceSuggestionChat';

interface ControlledChatInputProps {
  className?: string;
  disabled?: boolean;
  editable?: boolean;
  defaultValue?: string;
  placeholder?: string;
  valueSuffix?: string;
  hasFocus?: boolean;
  onSend: (value: string) => void;
  multiline?: boolean;
  onSubmitEditing?: () => void;
  maxLength?: number;
  onPressIcon?: () => void;
  keyboardType?: 'email' | 'text' | 'tel';
}

type Props<TFieldValue extends FieldValues> = ControlledChatInputProps & {
  control: Control<TFieldValue>;
  rules?: Partial<RegisterOptions<TFieldValue>>;
  resetField?: UseFormResetField<TFieldValue>;
  name: FieldPath<TFieldValue>;
};

export const ControlledChatInput = ({
  control,
  name,
  rules,
  className,
  disabled = false,
  editable,
  placeholder,
  defaultValue = '',
  valueSuffix,
  hasFocus = false,
  resetField,
  onSend,
  ...props
}: Props<PriceSuggestionFormValues>) => (
  <div>
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <ChatInput<PriceSuggestionFormValues>
          field={field}
          resetField={resetField}
          className={className}
          onChangeText={field.onChange}
          placeholder={placeholder}
          disabled={disabled}
          editable={editable}
          hasFocus={hasFocus}
          value={field.value ?? defaultValue}
          valueSuffix={valueSuffix}
          onSend={onSend}
          error={fieldState.error?.message}
          type={name}
          {...props}
        />
      )}
    />
  </div>
);
