import React from 'react';

import CommonQuestionsBlock from './CommonQuestionsBlock';
import GadgetsAreSafeBlock from './GadgetsAreSafeBlock';
import GoodToKnowBlock from './GoodToKnowBlock';
import { ImportantDocumentsBlock } from './ImportantDocumentBlock';

const InformationList = () => {
  return (
    <div className="no-scrollbar rounded-2xl md:overflow-auto md:rounded-[32px]">
      <ul>
        <li className="mb-0.5">
          <GoodToKnowBlock />
        </li>
        <li className="mb-0.5">
          <GadgetsAreSafeBlock />
        </li>
        <li className="mb-0.5">
          <CommonQuestionsBlock
            titleClassName="md:text-[40px] md:pb-6 pb-4"
            accordionTitleClassName="md:text-[24px] text-xl font-normal"
            dividerClassName="md:py-6 border-[#EBEDF0]"
            accordionTextClassName="md:text-xl"
          />
        </li>
        <li className="mb-0">
          <ImportantDocumentsBlock />
        </li>
      </ul>
    </div>
  );
};

export default InformationList;
