import { useEffect } from 'react';

export const useHideCookiesButton = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const cookiesButton = document.getElementById('ot-sdk-btn-floating');

        if (cookiesButton) cookiesButton.style.display = 'none';
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => observer.disconnect();
  }, []);
};
