import { UserInfo } from '@core/types';

export type OnboardingChatForm = Pick<
  UserInfo,
  'age' | 'postalCode' | 'residents' | 'accomodationType' | 'isRenting'
>;

export enum ACCOMODATION_TYPE {
  APARTMENT,
  HOUSE
}
