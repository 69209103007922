import React from 'react';

import { twMerge } from 'tailwind-merge';

import { Message } from '@core/types';

import ChatBackButton from './ChatBackButton';
import ChatConversation from './ChatConversation';
import ChatPageBlock from './ChatPageBlock';

type ChatLayoutProps = {
  messages: Message[];
  children: React.ReactNode;
  chatClasses?: string;
  onBackClick?: () => void;
  canGoBack?: boolean;
  footerClasses?: string;
};

const ChatLayout = ({
  messages,
  children,
  chatClasses,
  onBackClick,
  canGoBack = true,
  footerClasses
}: ChatLayoutProps) => {
  return (
    <div className="relative h-full">
      <ChatPageBlock>
        <div className="no-scrollbar relative flex flex-grow justify-center pb-8">
          <ChatConversation
            messages={messages}
            className={twMerge(
              typeof onBackClick === 'function' ? 'md:pt-[52px]' : 'pt-4',
              chatClasses
            )}
          />
        </div>
        <div
          className={twMerge(
            'no-scrollbar absolute bottom-0 left-0 right-0 flex items-end justify-center overflow-auto bg-white pb-4 md:left-0 md:right-0',
            footerClasses
          )}
        >
          {children}
        </div>
      </ChatPageBlock>
      <div className="absolute left-0 right-0 top-0 flex justify-center">
        {canGoBack && <ChatBackButton onClick={onBackClick} />}
      </div>
    </div>
  );
};

export default ChatLayout;
