import { useRouter } from 'next/router';

export const useUpdateCardQueryParameters = () => {
  const router = useRouter();
  const paymentId = router.query['paymentid'];

  return {
    paymentId: paymentId as string
  };
};
