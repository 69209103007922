import React, { useState, useRef } from 'react';

interface FloatingLabelInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  className?: string;
  disabled?: boolean;
}

const FloatingLabelInput: React.FC<FloatingLabelInputProps> = ({
  label,
  value,
  onChange,
  type = 'text',
  disabled = false,
  className
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const labelTextClasses = `absolute text-base font-bold transition-all duration-200 ${
    value || isFocused
      ? 'top-0 left-2 text-black'
      : 'top-4 left-2 text-greySemiLight'
  }`;

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`relative py-4 ${className}`}>
      <input
        id={'input'}
        type={type}
        value={value}
        onChange={onTextChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        ref={inputRef}
        className="block w-full p-2 text-base focus:outline-none"
      />
      <label
        htmlFor={'input'}
        className={labelTextClasses}
        onClick={handleLabelClick}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
