import React from 'react';

import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

import QRCodeAppStore from '@assets/images/qr-code-app-store.svg';
import QRCodePlayStore from '@assets/images/qr-code-google-play.svg';
import SafetyByCiliaSuccessImage from '@assets/images/safety-by-cilia-success.webp';
import SejfaApp from '@assets/images/sejfa-app.webp';
import { Block, Button, Icon, ImageBlock } from '@components/index';
import { NextPageWithLayout } from '@pages/_app';

import { useSafetyByCiliaTranslation } from '../translation';

export const SafetyByCiliSuccessPage: NextPageWithLayout = () => {
  const t = useSafetyByCiliaTranslation();

  const renderAppQR = (
    src: string | StaticImageData,
    title: string,
    onClick: () => void
  ) => {
    return (
      <div className="max-h-[140px] max-w-[140px] rounded-2xl border border-greySemiLight p-2">
        <Image src={src} alt="onboarding-success-image" />
        <Button
          icon="arrow-angle-up"
          className="mt-4 h-[40px] w-full justify-start self-end bg-transparent pl-2"
          onClick={onClick}
          hugged
        >
          {title}
        </Button>
      </div>
    );
  };

  const renderAppButton = (title: string, onClick: () => void) => {
    return (
      <Block
        className="flex h-[162px] w-[167px] cursor-pointer flex-col justify-between rounded-2xl bg-success p-4 active:bg-success/40 active:text-black/40"
        onClick={onClick}
      >
        <Icon name="arrow-angle-up" className="self-end" />
        <div className="text-2xl font-semibold">{title}</div>
      </Block>
    );
  };

  const openUrl = (url: string) => window.open(url, '_blank');

  const goToPlayStore = () =>
    openUrl('https://play.google.com/store/apps/details?id=nu.sejfa.android');

  const goToAppStore = () =>
    openUrl('https://apps.apple.com/us/app/sejfa/id6449179160');

  return (
    <div className="grid flex-auto grid-cols-1 gap-0.5">
      <ImageBlock
        key={'first'}
        src={SafetyByCiliaSuccessImage}
        rowReverse={true}
        title="Grattis, du är sejf!"
        titleClassName="md:text-[72px]"
        desc="En kod för ditt Safety By Cilia abonnemang mailas till dig inom 24 timmar! Har du några frågor är det bara att höra av dig till oss på chatten ❤️"
      />
      <Block className="hidden items-center justify-center bg-white md:flex">
        <div className="flex md:justify-end md:pb-10 md:pr-[116px] md:pt-10">
          <div className="flex w-[500px] items-center justify-end">
            <Image src={SejfaApp} alt="Sejfa Mobile App" height={591} />
          </div>
        </div>
        <div className="column md:mr-32">
          <p className={twMerge('text-[40px] font-semibold')}>
            {t('SUCCESS_PAGE.TITLE')}
          </p>
          <p className="font-semi-bold my-4 text-xl md:my-6">
            {t('SUCCESS_PAGE.DESC')}
          </p>
          <div className="hidden space-x-9 md:flex">
            {renderAppQR(
              QRCodeAppStore,
              t('SUCCESS_PAGE.APP_STORE'),
              goToAppStore
            )}
            {renderAppQR(
              QRCodePlayStore,
              t('SUCCESS_PAGE.PLAY_STORE'),
              goToPlayStore
            )}
          </div>
          <div className="flex space-x-9 md:hidden">
            {renderAppButton(t('SUCCESS_PAGE.APP_STORE_MOBILE'), goToAppStore)}
            {renderAppButton(
              t('SUCCESS_PAGE.PLAY_STORE_MOBILE'),
              goToPlayStore
            )}
          </div>
        </div>
      </Block>
    </div>
  );
};
