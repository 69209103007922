import React from 'react';

import { Button } from '@components/index';

import { useOnboardingTranslation } from '../translation';

type IdentifyUserButtonProps = {
  onPress: () => void;
  isLoading: boolean;
};

const IdentifyUserButton = ({
  onPress,
  isLoading
}: IdentifyUserButtonProps) => {
  const t = useOnboardingTranslation();

  return (
    <Button
      className="h-[72px] w-full max-w-[383px] self-center"
      isLoading={isLoading}
      onClick={onPress}
      icon="bank-id"
      iconClassName="w-10 ml-0"
      disabled={isLoading}
    >
      {t('IDENTIFICATION_SCREEN.IDENTIFY_WITH_BANK_ID')}
    </Button>
  );
};

export default IdentifyUserButton;
