import axios from 'axios';

import {
  GetPaymentsRequest,
  GetPaymentsResponse,
  PolicyResponse,
  ChangeCardRequest,
  ChangeCardStatusRequest
} from './types';
import { createRequest } from '../../api-definition';

export const getPoliciesList = createRequest<PolicyResponse, void>({
  path: 'Policies',
  method: 'GET'
});

export const getPayments = createRequest<
  GetPaymentsResponse,
  GetPaymentsRequest
>({
  path: 'Policies/Payments',
  method: 'GET'
});

export const changeCard = (data: ChangeCardRequest) =>
  axios.post('/api/profile/update-card', data);

export const changeCardStatus = (data: ChangeCardStatusRequest) =>
  axios.post('/api/profile/update-card-status', data);
