import createUserAgentParser from '@lib/user-agent-parser';

export const isInApp = () => {
  const { getBrowser } = createUserAgentParser();
  const browser = getBrowser();

  const inAppBrowsers = ['Instagram', 'Facebook'];

  return browser.name ? inAppBrowsers.includes(browser.name) : false;
};
