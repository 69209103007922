import { MANDATORY_TRANSPORTS } from './config';
import { TrackingTransport } from './types';

export const getFinalTransports = (transports: TrackingTransport[] = []) => {
  const uniqueTransportMap = [...MANDATORY_TRANSPORTS, ...transports].reduce(
    (prev, cur) => {
      if (!prev[cur.name]) {
        prev[cur.name] = cur;
      }
      return prev;
    },
    {} as { [key: string]: TrackingTransport }
  );
  return Object.values(uniqueTransportMap);
};
