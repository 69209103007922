import { GetTemplateResponse, UserInfoResponse } from '@core/api';

export const useTemplateMock: GetTemplateResponse = {
  result: {
    productId: '1',
    userInfo: {
      givenName: 'Kalle',
      firstName: 'Kalle',
      lastName: 'Svensson',
      dateOfBirth: '1995-01-01',
      emailId: 'kalle@gmail.se',
      isEmailVerified: false,
      countryCallingCode: 'se',
      mobileNumber: '070707070'
    },
    homeData: {
      isPostalCodeMatch: true,
      postalCode: 41234,
      address: 'Hamngatan 12',
      city: 'Göteborg',
      numberOfResidents: 1,
      policyStartDate: '2023-06-01',
      paymentPeriodId: 1,
      residentsList: [{ personalNumber: '19950101' }],
      paymentPeriodList: [
        {
          value: 1,
          name: 'Yearly'
        },
        {
          value: 2,
          name: 'Monthly'
        }
      ]
    },
    premium: 1200,
    monthlyPremium: 100,
    currency: 'SEK',
    campaignCode: null,
    discountCode: null,
    discountMonthlyPremium: null,
    discountPremiumAmount: null,
    firstPremiumAmount: null,
    preDiscountPremium: null
  }
};

export const createTokenMock = {
  result: {
    authorizationKey: 'mocked-key'
  }
};

export const userInfoMock: UserInfoResponse = {
  result: {
    dateOfBirth: '1990-01-01',
    emailId: 'mock@mock.se',
    firstName: 'Mock',
    givenName: 'Mock',
    isEmailVerified: true,
    lastName: 'Mocksson',
    mobileNumber: '0701234567',
    partyNumber: 123,
    userAddressList: [
      {
        address: 'Mockgatan 1',
        city: 'Stockholm',
        country: 'Sweden',
        postalCode: 12345
      }
    ]
  }
};
