import { useFeatureValue as _useFeatureValue } from '@growthbook/growthbook-react';

import { AppFeatures } from '../types';

const useFeatureValue = <AppFeatureId extends keyof AppFeatures>(
  id: AppFeatureId
): AppFeatures[AppFeatureId] | null =>
  _useFeatureValue(id, null) as AppFeatures[AppFeatureId] | null;

export default useFeatureValue;
