import { PaymentLanguage } from '@core/types';

export const PAYMENT_LOCALE_BY_LANGUAGE: Record<PaymentLanguage, string> = {
  en: 'en-GB',
  sv: 'sv-SE'
};

export const PAYMENT_URL_QUERY_PARAMETERS: Record<string, string> = {
  language: 'language'
} as const;

export const DEFAULT_PAYMENT_LOCALE = PAYMENT_LOCALE_BY_LANGUAGE['sv'];
