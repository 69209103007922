import React from 'react';

import { Link } from '@components/index';
import { useTranslation } from '@core/translation';

const YourIntegrityLink: React.FC = () => {
  const t = useTranslation('EARLY_ADOPTER_PAGE');
  return (
    <div className="ml-10 mt-0">
      <span className="text-sm">{t('YOUR_INTEGRITY_LINK')}</span>
      <Link
        className="text-sm text-primary"
        link="/din-integritet"
        title={t('HERE')}
      />
    </div>
  );
};

export default YourIntegrityLink;
