import { PAYMENT_URL_QUERY_PARAMETERS } from '@core/payment';

export type PaymentFrequency = 'month' | 'year';

export type PaymentMethod = 'Swish' | 'Card' | 'Apple Pay';

export type PaymentInfo = {
  paymentMethod: PaymentMethod;
  paymentDate: string;
  amount: number;
};

export type Currency = 'SEK' | 'USD' | 'EUR' | 'NOK' | 'GBP';

// See: https://en.wikipedia.org/wiki/Currency_symbol
export const CURRENCY_SYMBOLS: Record<Currency, string> = {
  SEK: 'kr',
  USD: '$',
  EUR: '€',
  NOK: 'kr',
  GBP: '£'
} as const;

export type PaymentLanguage = 'en' | 'sv';

export type PAYMENT_QUERY_PARAMETER_KEY =
  keyof typeof PAYMENT_URL_QUERY_PARAMETERS;
