import React, { useEffect, useRef } from 'react';

export const TrustPilotWidget = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    // Explicit widths set to container of widget since it's not responsive otherwise
    <div className="w-[300px] md:w-[1200px]">
      <div
        className="trustpilot-widget"
        data-locale="sv-SE" // TODO: Pass as prop when needed
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="646f61b94121be1c9a92a8f3"
        data-style-height="240px"
        data-style-width="100%"
        data-stars="5"
        data-review-languages="sv"
        data-text-color="#000000"
      >
        <a
          href="https://se.trustpilot.com/review/sejfa.nu"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
