import { QueryClient } from '@tanstack/react-query';

import { createStore } from '@lib/state-management';

type QueryStore = {
  queryClient?: QueryClient;
};

const initialState: QueryStore = {
  queryClient: undefined
};

const updateFunctions = {
  updateQueryStore: (queryStore: Partial<QueryStore>) => () => queryStore
};

export const {
  useSelectFromStore: useSelectFromQueryStore,
  updateStore: updateQueryStore,
  resetStore: resetQueryStore
} = createStore(initialState, updateFunctions);
