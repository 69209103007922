import { mutations, useErrorCodeTranslation } from '@core/api';
import { useMessaging } from '@core/messaging';
import { logger } from '@lib/logger';

export const useConvertQuoteToPolicy = () => {
  const { addMessage } = useMessaging();
  const { useConvertQuoteToPolicy: convertQuoteToPolicy } = mutations.quotes;
  const { mutateAsync, isLoading } = convertQuoteToPolicy({
    onError: (error) => {
      logger.error('failed to convert quote to policy', error);
      addMessage({
        message: getErrorTranslation(error?.data?.code),
        payload: error
      });
    }
  });
  const { getErrorTranslation } = useErrorCodeTranslation();
  return { createPolicy: mutateAsync, isLoading };
};
