import React from 'react';

import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import AddIcon from '@assets/icons/add-icon.svg';
import AppleIcon from '@assets/icons/apple-icon.svg';
import ApplePay from '@assets/icons/apple-pay.svg';
import ArrowAngleUp from '@assets/icons/arrow-angle-up.svg';
import ArrowDownIcon from '@assets/icons/arrow-down.svg';
import ArrowForward from '@assets/icons/arrow-forward.svg';
import ArrowUpIcon from '@assets/icons/arrow-up.svg';
import ArrowUpwardIcon from '@assets/icons/arrow-upward.svg';
import BankIdIcon from '@assets/icons/bank-id-icon.svg';
import ChatIcon from '@assets/icons/chat-icon.svg';
import CheckedIcon from '@assets/icons/checked.svg';
import CloseIcon from '@assets/icons/close-icon.svg';
import EditIcon from '@assets/icons/edit-icon.svg';
import GoogleIcon from '@assets/icons/google-icon.svg';
import InfoOutline from '@assets/icons/info-outline.svg';
import Mastercard from '@assets/icons/mastercard.svg';
import OpenIcon from '@assets/icons/open-icon.svg';
import Payment from '@assets/icons/payment.svg';
import PersonOutline from '@assets/icons/person-outline.svg';
import QuestionIcon from '@assets/icons/question-icon.svg';
import RemoveIcon from '@assets/icons/remove-icon.svg';
import Swish from '@assets/icons/swish.svg';
import Visa from '@assets/icons/visa.svg';

export type IconType =
  | 'arrow-down'
  | 'arrow-up'
  | 'arrow-upward'
  | 'arrow-forward'
  | 'edit'
  | 'close'
  | 'question'
  | 'open'
  | 'chat'
  | 'add'
  | 'remove'
  | 'bank-id'
  | 'arrow-angle-up'
  | 'checked'
  | 'apple-icon'
  | 'google-icon'
  | 'info-outline'
  | 'swish'
  | 'visa'
  | 'mastercard'
  | 'person-outline'
  | 'apple-pay'
  | 'payment';

const getIcon: Record<IconType, string> = {
  'arrow-forward': ArrowForward,
  edit: EditIcon,
  close: CloseIcon,
  question: QuestionIcon,
  'arrow-down': ArrowDownIcon,
  open: OpenIcon,
  'bank-id': BankIdIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-upward': ArrowUpwardIcon,
  chat: ChatIcon,
  add: AddIcon,
  remove: RemoveIcon,
  'arrow-angle-up': ArrowAngleUp,
  'info-outline': InfoOutline,
  checked: CheckedIcon,
  'apple-icon': AppleIcon,
  'google-icon': GoogleIcon,
  swish: Swish,
  visa: Visa,
  mastercard: Mastercard,
  'apple-pay': ApplePay,
  'person-outline': PersonOutline,
  payment: Payment
};

type IconProps = { name?: IconType; className?: string };

const Icon = ({
  name = 'edit',
  className = 'w-6',
  size = 32
}: IconProps & { size?: number }) => {
  const src = getIcon[name];

  const classes = twMerge('right-0', className);
  return (
    <Image
      src={src}
      alt="icon"
      className={classes}
      width={size}
      height={size}
    />
  );
};

export default Icon;
