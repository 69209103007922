import { useTemplateMock } from './mocks';
import {
  getInitiate,
  getQuoteDetailsById,
  getAllList,
  getViewById,
  getTemplate
} from '../requests';
import { createQuery, createQueryWithMock } from '../store';

const useGetInitiate = createQuery('getInitiate', getInitiate);

const useGetQuoteDetailsById = createQuery(
  'getQuoteDetailsById',
  getQuoteDetailsById
);

const useGetAllList = createQuery('getAllList', getAllList);

const useGetViewById = createQuery('getViewById', getViewById);

const useGetTemplate = createQueryWithMock(
  'getTemplate',
  getTemplate,
  useTemplateMock
);

const quotes = {
  useGetInitiate,
  useGetQuoteDetailsById,
  useGetAllList,
  useGetViewById,
  useGetTemplate
};

export default quotes;
