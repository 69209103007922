import { useCallback, useSyncExternalStore } from 'react';

import {
  CreateStoreResult,
  StoreListener,
  Selector,
  UpdateFunctions
} from './types';

export const createStore = <State, Updates extends UpdateFunctions<State>>(
  initialState: State,
  updateFunctions: Updates
): CreateStoreResult<State, Updates> => {
  let state: State = initialState;

  const listeners: Set<StoreListener> = new Set();

  const subscribe: (listener: StoreListener) => () => boolean = (
    listener: StoreListener
  ) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const updateStore = <UpdaterName extends keyof Updates>(
    updaterName: UpdaterName
  ) => {
    const updaterFunction = updateFunctions[updaterName];
    return (...params: Parameters<typeof updaterFunction>) => {
      state = { ...state, ...updaterFunction(...params)(state) };
      listeners.forEach((listener) => listener());
    };
  };

  const useSelectFromStore = <Selected>(selector: Selector<State, Selected>) =>
    useSyncExternalStore(
      subscribe,
      useCallback(() => selector(state), [selector]),
      useCallback(() => selector(state), [selector])
    );

  const resetStore = () => {
    state = initialState;
  };

  return {
    updateStore,
    resetStore,
    useSelectFromStore
  };
};
