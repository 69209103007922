import React, { useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import ChatbotSV from '@assets/images/sad.svg';
import { Block, Button, IconButton } from '@components/index';
import { usePaymentLink } from '@core/payment';
import { NextPageWithLayout } from '@pages/_app';

import { useOnboardingNavigation } from '../navigation';
import { resetStores } from '../stores';
import { useTrackEvent } from '../tracking';
import { useOnboardingTranslation } from '../translation';

const PaymentFailed: NextPageWithLayout = () => {
  const ot = useOnboardingTranslation();
  const { pay, isLoading } = usePaymentLink();
  const router = useRouter();
  const { quoteId } = router.query;
  const { trackEvent } = useTrackEvent();

  const { navigateToRoot } = useOnboardingNavigation();

  useEffect(() => {
    trackEvent('web_failed_payment');
  }, []);

  useEffect(() => {
    resetStores();
  }, []);

  const handleSubmit = () => {
    if (quoteId) {
      pay({ quoteId: Number(quoteId) });
    }
  };

  return (
    <div className="relative grid h-full flex-auto gap-0.5">
      <Block className="flex items-center justify-center bg-white">
        <div className="flex h-full flex-col items-center justify-center  space-y-10 p-4 md:max-w-[40%] md:p-0">
          <div className="flex justify-center">
            <Image src={ChatbotSV} alt="sad-image" />
          </div>
          <div className="space-y-6">
            <h2 className="text-[40px] md:text-7xl">
              {ot('PAYMENT_FAILED.TITLE')}
            </h2>
            <p className="text-xl font-medium">{ot('PAYMENT_FAILED.DESC')}</p>
          </div>
          <Button
            onClick={handleSubmit}
            className="h-[72px] w-full self-center"
            isLoading={isLoading}
          >
            {ot('PAYMENT_FAILED.BUTTON_TITLE')}
          </Button>
        </div>
      </Block>
      <IconButton
        name="close"
        onClick={navigateToRoot}
        className="absolute right-2 top-4 md:right-8 md:top-8"
      />
    </div>
  );
};

export default PaymentFailed;
