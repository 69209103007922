import { ReactNode } from 'react';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

type FeatureToggleProviderProps = {
  config: GrowthBook;
  children: ReactNode;
};
export const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = ({
  config,
  children
}) => {
  return (
    <GrowthBookProvider growthbook={config}>{children}</GrowthBookProvider>
  );
};
