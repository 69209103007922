import { GrowthBook } from '@growthbook/growthbook-react';

import env from '@core/env-config';

import { AppFeatures } from './types';

export const FeatureToggleConfig = new GrowthBook<AppFeatures>({
  apiHost: env.GROWTHBOOK_API_HOST,
  clientKey: env.GROWTHBOOK_CLIENT_KEY
});

export const loadFeatures = () => FeatureToggleConfig.loadFeatures();
