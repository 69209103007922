import LoadingPayment from './LoadingPayment';
import Onboarding from './Onboarding';
import OnboardingCancelled from './OnboardingCancelled';
import OnboardingFailed from './OnboardingFailed';
import OnboardingPayment from './OnboardingPayment';
import OnboardingSuccess from './OnboardingSuccess';
import PaymentFailed from './PaymentFailed';

export {
  OnboardingFailed,
  OnboardingCancelled,
  Onboarding,
  OnboardingSuccess,
  OnboardingPayment,
  LoadingPayment,
  PaymentFailed
};
