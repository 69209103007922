import { changeCard, changeCardStatus } from '../requests';
import { createMutation } from '../store';

const useChangeCard = createMutation({
  request: changeCard
});

const useChangeCardStatus = createMutation({
  request: changeCardStatus
});

export default {
  useChangeCard,
  useChangeCardStatus
};
