import { useRouter } from 'next/router';

import { PAYMENT_QUERY_PARAMETER_KEY, PaymentLanguage } from '@core/types';

import {
  DEFAULT_PAYMENT_LOCALE,
  PAYMENT_LOCALE_BY_LANGUAGE
} from './constants';

const mergeQueryParameters = (
  parameters: { key: PAYMENT_QUERY_PARAMETER_KEY; value: string | number }[]
): string =>
  parameters.reduce(
    (acc, parameter) => `${acc}&${parameter.key}=${parameter.value}`,
    ''
  );

const usePaymentQueryParameters = () => {
  const { locale } = useRouter();
  const paymentLocale =
    PAYMENT_LOCALE_BY_LANGUAGE[locale as PaymentLanguage] ??
    DEFAULT_PAYMENT_LOCALE;

  const parameters = [{ key: 'language', value: paymentLocale }];
  return mergeQueryParameters(parameters);
};

export default usePaymentQueryParameters;
