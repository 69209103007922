import { useRouter } from 'next/router';
import { z } from 'zod';

//https://medium.com/@Seb_L/typing-nextjs-router-query-params-with-zod-coerce-e240ab430c2b

export const useTypedRouter = <T extends z.Schema>(schema: T) => {
  const { query, ...router } = useRouter();

  return {
    query: schema.parse(query) as z.infer<typeof schema>,
    ...router
  };
};
