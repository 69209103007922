import {
  calculateQuoteMock,
  createQuoteMock,
  simulateQuoteMock
} from './mocks';
import {
  sendEmailVerificationLink,
  calculateQuote,
  createQuote,
  convertQuoteToPolicy,
  simulateQuote
} from '../requests';
import { createMutation, createMutationWithMock } from '../store';

const useSendEmailVerificationLink = createMutation({
  request: sendEmailVerificationLink
});

const useCalculateQuote = createMutationWithMock(
  { request: calculateQuote },
  calculateQuoteMock
);

const useCreateQuote = createMutationWithMock(
  {
    request: createQuote
  },
  createQuoteMock
);

const useConvertQuoteToPolicy = createMutation({
  request: convertQuoteToPolicy
});

const useSimulateQuote = createMutationWithMock(
  {
    request: simulateQuote
  },
  simulateQuoteMock
);

const quotes = {
  useSendEmailVerificationLink,
  useCalculateQuote,
  useCreateQuote,
  useConvertQuoteToPolicy,
  useSimulateQuote
};
export default quotes;
