import { logger } from '@lib/logger';

import { ToastMessage, updateMessageStore } from './messageStore';

const useMessaging = () => {
  const addMessage = (toastMessage: ToastMessage, hideToast = false) => {
    if (toastMessage.toastType === 'error') {
      logger.error(toastMessage.message, toastMessage.payload);
    }

    if (hideToast) {
      return;
    }

    updateMessageStore('add')(toastMessage);
  };

  const removeMessage = (toastMessage: ToastMessage) => {
    updateMessageStore('remove')(toastMessage);
  };

  return {
    addMessage,
    clearMessage: removeMessage
  };
};

export default useMessaging;
