import {
  GrowthBook,
  useGrowthBook as _useGrowthBook
} from '@growthbook/growthbook-react';

import { AppFeatures } from '../types';

const useGrowthBook = (): GrowthBook<AppFeatures> | undefined =>
  _useGrowthBook<AppFeatures>();
export default useGrowthBook;
