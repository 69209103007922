import env from '@core/env-config';
import { createQueryStore } from '@lib/query';

import { QueryKey } from './query-definitions';
import { IIBError } from './types';
const {
  createMutation,
  createQuery,
  createMutationWithMock,
  createQueryWithMock
} = createQueryStore<QueryKey, IIBError>({
  useMock: env.USE_MOCK === 'true'
});

export {
  createMutation,
  createQuery,
  createMutationWithMock,
  createQueryWithMock
};
