import React from 'react';

import { twMerge } from 'tailwind-merge';

type RadioButtonProps = React.ComponentProps<'input'> & {
  RightIcon?: React.ElementType;
};

const RadioButton = ({
  checked,
  onChange,
  value,
  className,
  RightIcon
}: RadioButtonProps) => {
  const baseClasses = ['font-bold', 'flex gap-2'];
  const ref = React.useRef<HTMLInputElement>(null);

  const textColor = twMerge('text-black', checked && 'text-white');

  const containerClasses = twMerge(
    'cursor-pointer',
    'flex',
    'h-14',
    'flex-row',
    'items-center',
    'justify-between',
    'rounded-2xl',
    'px-4',
    checked && 'bg-text',
    className
  );

  const inputClasses = ['w-6', 'h-6', 'accent-text', 'cursor-pointer'];
  const labelClasses = twMerge(baseClasses, textColor);

  const handleInputClick = () => {
    ref.current?.click();
  };

  return (
    <div className={containerClasses} onClick={handleInputClick}>
      <label className={labelClasses}>
        <input
          type="radio"
          name="payment-type"
          ref={ref}
          value={value}
          id="payment-type"
          checked={checked}
          className={inputClasses.join(' ')}
          onChange={onChange}
        />
        {value}
      </label>
      {RightIcon && <RightIcon />}
    </div>
  );
};

export default RadioButton;
