import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import Icon, { IconType } from '../Icon/Icon';

type AccordionProps = {
  title: string;
  subtitle?: string;
  content?: string | string[];
  expandedIcon?: IconType;
  unexpandedIcon?: IconType;
  titleClassName?: string;
  iconClassName?: string;
  textClassName?: string;
  contentClassName?: string;
};

const Accordion = ({
  title,
  subtitle,
  content,
  expandedIcon = 'arrow-down',
  unexpandedIcon = 'arrow-down',
  titleClassName,
  textClassName,
  iconClassName
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((current) => !current);

  const iconClass = twMerge(expanded && 'rotate-180 transform', iconClassName);

  const contentClass = twMerge(
    'overflow-hidden',
    'transition-[max-height]',
    'duration-200',
    'ease-in',
    'max-h-0',
    'pt-0',
    expanded && 'max-h-96'
  );

  const titleClasses = twMerge(
    'flex-1 text-[20px] font-semibold md:text-[32px]',
    titleClassName
  );

  const textClasses = twMerge(
    'whitespace-pre-line text-left text-base md:mt-0 md:text-xl',
    textClassName
  );

  return (
    <div className="cursor-pointer" onClick={toggleExpanded}>
      <div className="flex select-none flex-row items-center justify-between pr-3 text-left">
        <h4 className={titleClasses}>{title}</h4>
        <div className={iconClass}>
          <Icon
            name={
              expandedIcon && expanded
                ? expandedIcon
                : unexpandedIcon && !expanded
                ? unexpandedIcon
                : 'arrow-down'
            }
          />
        </div>
      </div>
      <div className={contentClass}>
        {Array.isArray(content) ? (
          <div className="mt-4 rounded-2xl bg-success p-6">
            <p className="text-left text-base md:mt-0 md:text-xl">{subtitle}</p>
            {content.map((item) => (
              <div className="flex" key={item}>
                <Icon name="checked" className="mr-4" />
                <p className="mt-2 whitespace-pre-line text-left md:text-xl">
                  {item}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="py-2" />
            <p className={textClasses}>{content}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Accordion;
