import React, { ReactNode } from 'react';

type TextProps = {
  children?: ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLDivElement
>;
// Temporary example
const Text: React.FC<TextProps> = ({ children }) => <label>{children}</label>;

export default Text;
