import { ChangeEvent, FC, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { IconButton, TextInput } from '@components/index';
import { useTranslation } from '@core/translation';

import { updateUserStore } from '../stores';

type AddressInputProps = {
  className?: string;
  disabled?: boolean;
  onSend: (value: string, address?: string, zip?: string) => void;
  initialAddress?: string;
  initialZip?: string;
  isLoading?: boolean;
};

const AddressInput: FC<AddressInputProps> = ({
  className,
  disabled = false,
  onSend,
  initialAddress = '',
  initialZip = '',
  isLoading
}) => {
  const updateUser = updateUserStore('updateUser');
  const [address, setAddress] = useState<string>(initialAddress);
  const [zip, setZip] = useState<string>(initialZip);

  const t = useTranslation('ONBOARDING.CONTACT_INFO');

  const onSubmit = () => {
    onSend(`${address}\n${zip}`, address, zip);
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
    updateUser({ address: event.target.value });
  };

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateUser({ postalCode: Number(event.target.value) });
    setZip(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <form
      className={twMerge(
        'flex flex-row rounded-3xl border-2 p-2 pl-6 pt-8',
        className
      )}
      onSubmit={handleSubmit}
    >
      <div className="flex-grow py-0">
        <TextInput
          floatingLabel={t('ADDRESS')}
          value={address}
          onChange={handleAddressChange}
          disabled={disabled}
          className="pb-3"
        />
        <hr className="mb-8 h-0.5 w-full self-center bg-greySemiLight" />
        <TextInput
          floatingLabel={t('POSTAL_CODE')}
          value={zip}
          onChange={handlePostalCodeChange}
          disabled={disabled}
          className="pb-3"
        />
      </div>
      <div className="self-end">
        <IconButton
          name="arrow-up"
          style={{ zIndex: 100 }}
          onClick={onSubmit}
          className="bg-primary"
          type="submit"
          disabled={isLoading || zip.length < 1 || address.length < 1}
          activityIndicatorContainer="ml-0"
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default AddressInput;
