import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { DefaultError } from '@lib/api';

import { KeyFromName, QueryKeyDefinition } from './types';

type UseQueryConfig<
  QueryKey extends QueryKeyDefinition<unknown>,
  KeyName extends QueryKey['name'],
  Result,
  ErrorType
> = {
  options?: Omit<
    UseQueryOptions<
      Result,
      ErrorType,
      Result,
      [KeyName, ...[KeyFromName<QueryKey, KeyName>['variables']]]
    >,
    'queryKey' | 'queryFn'
  >;
} & (KeyFromName<QueryKey, KeyName>['variables'] extends void
  ? { parameters?: KeyFromName<QueryKey, KeyName>['variables'] }
  : { parameters: KeyFromName<QueryKey, KeyName>['variables'] });

export type UseQueryParams<
  QueryKey extends QueryKeyDefinition<unknown>,
  KeyName extends QueryKey['name'],
  Result,
  ErrorType
> = KeyFromName<QueryKey, KeyName>['variables'] extends void
  ? [UseQueryConfig<QueryKey, KeyName, Result, ErrorType>?]
  : [UseQueryConfig<QueryKey, KeyName, Result, ErrorType>];

export const createQuery =
  <
    QueryKey extends QueryKeyDefinition<unknown>,
    KeyName extends QueryKey['name'],
    Result,
    ErrorType = DefaultError
  >(
    queryName: KeyName,
    request: (
      ...parameters: [KeyFromName<QueryKey, KeyName>['variables']]
    ) => Promise<Result>
  ) =>
  (...params: UseQueryParams<QueryKey, KeyName, Result, ErrorType>) => {
    const config = params[0];
    const { parameters, options } = config ?? {
      parameters: undefined,
      options: {}
    };

    return useQuery({
      queryKey: [queryName, parameters],
      queryFn: () => request(parameters),
      retry: false,
      ...options
    });
  };
