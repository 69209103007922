import { FC } from 'react';

type SpacerProps = {
  type: 'horizontal' | 'vertical';
  size: number;
};

export const Spacer: FC<SpacerProps> = ({ type, size }) => {
  const className = `${type === 'horizontal' ? 'w' : 'h'}-${size}`;
  return <div className={`${className}`} />;
};
