import {
  useLimitedOnboardingModal,
  GeneralInsuranceInfoModalContentSkeletonEntry
} from '@core/content-management';

export const useLimitedOnboardingInfo = (): {
  data?: GeneralInsuranceInfoModalContentSkeletonEntry;
} => {
  const { data } = useLimitedOnboardingModal();

  return {
    data
  };
};
