import React from 'react';

import { ContactInfoBlock } from '@components/index';
import { UserInfo } from '@core/types';

import FrequencyBlock from './FrequencyBlock';
import { ImportantDocumentsBlock } from './ImportantDocumentBlock';
import SummaryBlock from './SummaryBlock';
import { updateUserStore, useSelectFromUserStore } from '../stores';

const SummaryList = () => {
  const updateUser = updateUserStore('updateUser');
  const user = useSelectFromUserStore((state: UserInfo) => state);

  const handleContactInfoChange = (value: {
    key: keyof UserInfo;
    value: string;
  }) => {
    updateUser({ [value.key]: value.value });
  };

  return (
    <div className="no-scrollbar rounded-2xl md:overflow-auto md:rounded-[32px]">
      <ul>
        <li className="mb-0.5">
          <FrequencyBlock />
        </li>
        <li className="mb-0.5">
          <ContactInfoBlock
            userInfo={user}
            onChange={handleContactInfoChange}
            isEditingDisabled={false}
          />
        </li>
        <li className="mb-0.5">
          <SummaryBlock userInfo={user} onChange={handleContactInfoChange} />
        </li>
        <li className="mb-0.5">
          <ImportantDocumentsBlock />
        </li>
      </ul>
    </div>
  );
};

export default SummaryList;
