import { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';

import ChatbotSV from '@assets/images/sad.svg';
import { Block, Button, TextInput } from '@components/index';
import { useDeniedOffer } from '@core/api/';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { logger } from '@lib/logger';
import { useTypedRouter } from '@lib/utils';
import { z } from '@lib/validation';
import { NextPageWithLayout } from '@pages/_app';

import SkipButton from '../components/SkipButton';
import { useOnboardingNavigation } from '../navigation';
import {
  resetStores,
  updateOnboardingStore,
  useSelectFromUserStore
} from '../stores';
import { useTrackEvent } from '../tracking';
import { useOnboardingTranslation } from '../translation';

const routerSchema = z.object({
  status: z.coerce.string()
});

const OnboardingFailed: NextPageWithLayout = () => {
  const t = useTranslation('COMMON');
  const ot = useOnboardingTranslation();

  const { push, query } = useTypedRouter(routerSchema);
  const { trackEvent } = useTrackEvent();
  const resetOnboardinSteps = updateOnboardingStore('resetOnboarding');

  const trackOnboardingDenied = useCallback(() => {
    return () => trackEvent('web_denied_onboarding');
  }, [trackEvent]);

  useEffect(() => {
    trackOnboardingDenied();
  }, [trackOnboardingDenied]);

  useEffect(() => {
    return () => {
      resetOnboardinSteps();
    };
  }, []);

  useEffect(() => {
    trackOnboardingDenied();
  }, [trackOnboardingDenied]);

  useEffect(() => {
    return () => {
      storage().clearSessionStorage();
      resetStores();
    };
  }, []);

  const TEXTS: Record<
    'unavailable' | 'failed',
    {
      HEADER: string;
      SUB_HEADER: string;
      PROVIDE_EMAIL_DESC: string;
      ERROR_TEXT: string;
    }
  > = {
    failed: {
      HEADER: ot('FAILED_PAGE.HEADER'),
      SUB_HEADER: ot('FAILED_PAGE.SUB_HEADER'),
      PROVIDE_EMAIL_DESC: ot('FAILED_PAGE.PROVIDE_EMAIL'),
      ERROR_TEXT: ot('FAILED_PAGE.ERROR_TEXT')
    },
    unavailable: {
      HEADER: ot('UNAVAILABLE_PAGE.HEADER'),
      SUB_HEADER: ot('UNAVAILABLE_PAGE.SUB_HEADER'),
      PROVIDE_EMAIL_DESC: ot('UNAVAILABLE_PAGE.PROVIDE_EMAIL'),
      ERROR_TEXT: ot('UNAVAILABLE_PAGE.ERROR_TEXT')
    }
  };

  const TEXTS_BY_STATUS =
    query.status === 'unavailable' ? TEXTS.unavailable : TEXTS.failed;

  const { postalCode, email: userEmail } = useSelectFromUserStore(
    (state) => state
  );
  const [email, setEmail] = useState(userEmail ?? '');
  const [zip] = useState(postalCode ?? '');

  const emailSchema = z.object({
    email: z.string().email()
  });

  const { mutate: sendDeniedEmail } = useDeniedOffer({
    onError: (error) => {
      logger.error(TEXTS_BY_STATUS.ERROR_TEXT, error);
    }
  });
  const { navigateToRoot } = useOnboardingNavigation();

  const handleSubmit = () => {
    trackEvent('web_denied_onboarding_send');
    sendDeniedEmail({
      email: email,
      zip: zip?.toString() ?? ''
    });
    navigateToRoot();
  };

  const handleSkip = () => {
    trackEvent('web_denied_onboarding_skip');
    push('/');
  };

  return (
    <div className="grid min-h-full flex-auto  gap-0.5 md:grid-cols-2 md:grid-rows-1">
      <Block className="flex items-center justify-center bg-pressedPrimary">
        <div className="column max-w-[425px] space-y-4 p-4 md:space-y-20 md:p-0">
          <div className="flex justify-center">
            <Image src={ChatbotSV} alt="sad-image" />
          </div>
          <div className="space-y-3 md:space-y-6">
            <h2 className="text-4xl md:text-7xl">{TEXTS_BY_STATUS.HEADER}</h2>
            <p className="text-xl font-semibold md:text-3xl">
              {TEXTS_BY_STATUS.SUB_HEADER}
            </p>
          </div>
        </div>
      </Block>
      <Block className="flex h-full items-center justify-center bg-white">
        <div className="column flex max-w-[384px] flex-col justify-between p-4">
          <p className="font-semi-bold text-l mb-4 md:my-12 md:text-xl ">
            {TEXTS_BY_STATUS.PROVIDE_EMAIL_DESC}
          </p>
          <TextInput
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            floatingLabel={t('EMAIL')}
            className="w-full rounded-none border-b-2 border-black pl-0"
            defaultValue={email}
          />
          <Button
            onClick={handleSubmit}
            disabled={!emailSchema.safeParse({ email }).success}
            className="mt-6 h-[72px] w-full bg-text text-white"
          >
            {t('SEND_AND_CLOSE')}
          </Button>
          <SkipButton
            className="self-center duration-500 ease-in-out hover:opacity-60 active:opacity-100"
            onClick={handleSkip}
            text={ot('FAILED_PAGE.DONT_INFORM')}
          />
        </div>
      </Block>
    </div>
  );
};

export default OnboardingFailed;
