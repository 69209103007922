import React from 'react';

import { twMerge } from 'tailwind-merge';

import { IconButton } from '@components/index';

import { useOnboardingTranslation } from '../translation';

type OnboardingProgressBarProps = {
  style?: React.CSSProperties;
  step: number;
  totalSteps: number;
  progressionStep: number;
  totalProgressionSteps: number;
  title: string;
  toggle: () => void;
  className?: string;
};

const OnboardingProgressBar: React.FC<OnboardingProgressBarProps> = ({
  step,
  totalSteps,
  progressionStep,
  totalProgressionSteps,
  title,
  toggle,
  className
}) => {
  let progressPercentage = 0;
  const t = useOnboardingTranslation();
  if (progressionStep && totalProgressionSteps) {
    progressPercentage = (progressionStep / totalProgressionSteps) * 100;
  }

  const containerClasses = twMerge(
    'relative flex items-center justify-between rounded-2xl md:rounded-[32px] bg-white px-4 py-6 md:px-12 border-b-2',
    className
  );

  const progressClasses = twMerge(
    'absolute inset-y-0 left-0 rounded-2xl  md:rounded-[32px] bg-pressedPrimary transition-all duration-500',
    progressPercentage === 100 && 'rounded-2xl md:rounded-r-[32px]'
  );

  return (
    <div className={containerClasses}>
      <div
        className={progressClasses}
        style={{ width: `${progressPercentage}%`, zIndex: 10 }}
      />

      <div className="flex flex-col">
        <span className="text-gray-600 z-10 text-xs font-bold uppercase leading-5 tracking-[0.96px] md:text-base">
          {t('STEP')}
          {step}/{totalSteps}
        </span>
        <span className="z-10 text-2xl font-bold md:text-[32px]">{title}</span>
      </div>
      <IconButton style={{ zIndex: 11 }} name="close" onClick={toggle} />
    </div>
  );
};

export default OnboardingProgressBar;
