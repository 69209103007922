import { useEffect, useState } from 'react';

import {
  CustomEvent,
  EventParams,
  TrackingTransport,
  getFinalTransports
} from './internal';

export const createEventTracker = <EventName extends string>(
  featureName: string,
  defaultTransports?: TrackingTransport[]
) => {
  return (eventTransports?: TrackingTransport[]) => {
    const transportNames = (eventTransports ?? []).map((t) => t.name);
    const trackingEnabled = true; // TODO: Get from cookie consent or always be true and let GTM handle it.
    const [event, setEvent] = useState<CustomEvent<EventName>>();

    useEffect(() => {
      if (event && (trackingEnabled || event.enableTracking)) {
        const innerTrackEvent = async () => {
          const {
            name: eventName,
            params: EventParams,
            enableTracking: enableTracking
          } = event;
          const transports = getFinalTransports(
            eventTransports ?? defaultTransports
          );
          await Promise.all(
            transports.map(
              (t) => t.trackEvent(eventName, EventParams, enableTracking) // TODO: Would we want all features to follow a certain structure involving page / feature name?
            )
          );
        };
        innerTrackEvent();
      }
    }, [event, trackingEnabled, ...transportNames]);

    return {
      trackEvent: (
        name: EventName,
        params?: EventParams,
        enableTracking?: boolean
      ) => setEvent({ name, params, enableTracking })
    };
  };
};
