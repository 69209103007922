import { useRef } from 'react';

export const useDebounce = (debounceTimerMs?: number) => {
  const busy = useRef(false);
  const BOUNCE_RATE = debounceTimerMs ?? 500;

  const debounce = async (callback: () => unknown) => {
    setTimeout(() => {
      busy.current = false;
    }, BOUNCE_RATE);

    if (!busy.current) {
      busy.current = true;
      callback();
    }
  };

  return { debounce };
};
