import React from 'react';

import TermsImg from '@assets/images/terms-img.webp';
import { useTermsSejfa } from '@core/content-management';
import { NextPageWithLayout } from '@pages/_app';

import { RichTextPage, Footer } from '../components';

const TermsPage: NextPageWithLayout = () => {
  const { data: termPageData } = useTermsSejfa();

  const TermsData = termPageData?.fields?.textContent;
  return (
    <>
      <div className="min-w-screen min-h-screen flex-1 rounded-b-[32px] bg-white md:min-h-[900px]">
        {TermsData && (
          <RichTextPage src={TermsImg} Text={TermsData ?? undefined} />
        )}
      </div>
      <div className="bg-black pb-12 md:p-20">
        <Footer />
      </div>
    </>
  );
};

export default TermsPage;
