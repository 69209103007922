import Image from 'next/image';

import Img from '@assets/images/sejfa-app.webp';
import { Block, Button } from '@components/index';

import { useLandingPageNavigation } from '../navigation';
import { useLandingPageTranslation } from '../translation';

const SejfaBlock = () => {
  const t = useLandingPageTranslation();
  const { navigateToOnboarding } = useLandingPageNavigation();
  return (
    <Block className="flex w-full justify-center bg-white">
      <div className="grid max-w-[1216px] grid-cols-1 space-y-12 bg-white px-4 py-14 md:grid-cols-2 md:py-28">
        <div className="flex md:justify-start md:pr-[116px]">
          <div className="flex w-[500px] items-center justify-center">
            <Image src={Img} alt="image-1" height={591} />
          </div>
        </div>
        <div className="flex flex-col text-black md:-ml-[116px] md:items-start">
          <div>
            <div className="h-0 w-full md:h-4" />
            <div className="max-w-[592px]">
              <h2 className="text-[40px] font-semibold md:mt-3 md:text-7xl">
                {t('SEJFA_APP_SECTION.TITLE')}
              </h2>
            </div>
            <p className="font-semi-bold my-6 text-2xl font-medium md:my-10">
              {t('SEJFA_APP_SECTION.DESC')}
            </p>
            <Button
              onClick={() => navigateToOnboarding()}
              className="h-[72px] w-full md:max-w-[343px]"
              variant="secondary"
            >
              {t('SEJFA_APP_SECTION.BUTTON_TITLE')}
            </Button>
          </div>
        </div>
      </div>
    </Block>
  );
};

export default SejfaBlock;
