import { useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, duration: number) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const timeout = setTimeout(() => savedCallback.current(), duration);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
};
