export const env = {
  ENV: process.env.NEXT_PUBLIC_ENV,
  ASSENTLY_CORE_ID_SCRIPT_URL:
    process.env.NEXT_PUBLIC_ASSENTLY_CORE_ID_SCRIPT_URL,
  WEBVIEW_BASE_URL: process.env.NEXT_PUBLIC_WEBVIEW_BASE_URL,
  USE_MOCK: process.env.NEXT_PUBLIC_USE_MOCK,
  INSURANCE_IN_A_BOX_URL: process.env.NEXT_PUBLIC_INSURANCE_IN_A_BOX_URL,
  INSURANCE_IN_A_BOX_KEY: process.env.NEXT_PUBLIC_INSURANCE_IN_A_BOX_KEY,
  BASE_URL_REFERER: process.env.NEXT_PUBLIC_BASE_URL_REFERER,
  SENTRY_DSN_PUBLIC: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_DSN: process.env.SENTRY_DSN,
  CONTENTFUL_SPACE_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  PAYMENT_TERMS_URL: process.env.NEXT_PUBLIC_PAYMENT_TERMS_URL,
  GROWTHBOOK_API_HOST: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  DOCUMENTS_URL: process.env.NEXT_PUBLIC_DOCUMENTS_URL,
  GTM_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  SEJFA_API_URL: process.env.NEXT_PUBLIC_SEJFA_API_URL
};

export default env;
