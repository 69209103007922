import Terms from '@assets/images/accord-terms.webp';
import AccordWhatIsSejfa from '@assets/images/accord-what-is-sejfa.webp';
import Bed from '@assets/images/bed.webp';
import Ikea from '@assets/images/ikea.webp';
import { Footer, useLandingPageNavigation } from '@features/landing-page';
import { NextPageWithLayout } from '@pages/_app';

import Hero from '../components/Hero';
import ImageBlock from '../components/ImageBlock';
import { DISCOUNT_CODE } from '../constants';

export const AccordPage: NextPageWithLayout = () => {
  const { navigateToOnboarding } = useLandingPageNavigation();

  const sections = [
    {
      title: 'Vad är Sejfa?',
      desc: 'En smart, digital och trygg hemförsäkring för dig, ditt hem och dina prylar. I sejfa ingår prylförsäkring upp till 1 miljon kr, reseförsäkring i 45 dagar och bostadsrättstillägg.',
      imgSrc: AccordWhatIsSejfa,
      rowReverse: false
    },
    {
      title: 'Vad täcker Sejfas hemförsäkring?',
      desc: `Mer än du anar! Stulen cykel, borttappade airpods, spillt kaffe över datorn, inbrott i förrådet eller missade flyget på grund av bilkö? Det finns många fler situationer där du hade önskat att du hade en hemförsäkring än vad du kanske vet om. Särskilt som student!`,
      imgSrc: Bed,
      rowReverse: true
    },
    {
      title: 'Bli sejf och få 300kr på Ikea',
      desc: `Vi vill förenkla och göra livet tryggare för dig som student. Accord hjälper dig ta kontrollen över dina To do's i livet och med Sejfa har du ditt hem och dina prylar på det torra. Just nu erbjuder vi dessutom alla Accords användare 40% rabatt första året och ett presentkort på Ikea för 300kr. Happy shopping!\n\nDet digitala presentkortet skickas till din mail inom 48 timmar efter att du tecknat hemförsäkring.`,
      imgSrc: Ikea,
      rowReverse: false
    },
    {
      title: 'Regler och villkor',
      desc: '',
      imgSrc: Terms,
      rowReverse: true,
      list: [
        'Villkor för att nyttja erbjudandet är att du har din hemförsäkring tecknad hos Sejfa 12 månader framåt, förutsatt att du fortsatt har ett behov av försäkring som Sejfas försäkring täcker.',
        'Skulle du säga upp din försäkring innan 12 månader har gått från dagen då försäkringen tecknades blir du återbetalningsskyldig för värdet av presentkortet (300 kr)',
        'Du kan inte vara kund hos Sejfa sen tidigare',
        'Endast ett erbjudande per hushåll kan användas.'
      ]
    }
  ];

  const openOnboardingWithDiscountCode = (code: string) => {
    navigateToOnboarding(code);
  };

  return (
    <div className="space-y-0.5 rounded-2xl md:rounded-[32px]">
      <Hero />
      <ImageBlock
        key={'first'}
        src={sections[0].imgSrc}
        rowReverse={sections[0].rowReverse}
        title={sections[0].title}
        desc={sections[0].desc}
        titleClassName="md:text-7xl font-bold"
        imgContainerClassName="order-last pt-0"
        blockClassName="space-y-4"
      />
      <ImageBlock
        key={'second'}
        src={sections[1].imgSrc}
        rowReverse={sections[1].rowReverse}
        title={sections[1].title}
        desc={sections[1].desc}
        imgContainerClassName="order-first pt-0"
      />
      <ImageBlock
        key={'third'}
        src={sections[2].imgSrc}
        rowReverse={sections[2].rowReverse}
        title={sections[2].title}
        desc={sections[2].desc}
        titleClassName="md:text-7xl font-bold"
        imgContainerClassName="order-last pt-0"
        blockClassName="space-y-4"
        ctaTitle="Se ditt pris"
        cta={() => openOnboardingWithDiscountCode(DISCOUNT_CODE)}
      />
      <ImageBlock
        key={'second'}
        src={sections[3].imgSrc}
        rowReverse={sections[3].rowReverse}
        title={sections[3].title}
        desc={sections[3].desc}
        list={sections[3].list}
        imgContainerClassName="max-h-[488px]"
      />
      <div className="flex justify-center px-4 pb-12 md:py-20">
        <div className="w-full max-w-[1216px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};
