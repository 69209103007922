import React from 'react';

import {
  QueryClientProvider,
  QueryClient,
  QueryClientConfig
} from '@tanstack/react-query';

type UseCreateQueryClientProviderConfig = {
  queryClientConfig?: QueryClientConfig;
};

export const useCreateQueryClientProvider = ({
  queryClientConfig
}: UseCreateQueryClientProviderConfig = {}) => {
  const queryClient = new QueryClient(queryClientConfig);

  return ({ children }: { children: JSX.Element }) => (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
