import React, { ReactNode, useEffect } from 'react';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/navigation';

import { ErrorBoundary } from '@components/index';
import {
  FeatureToggleProvider,
  FeatureToggleConfig,
  useSetFeatureToggleAttributes
} from '@core/feature-toggles';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { resetStores } from '@features/onboarding';
import { logger } from '@lib/logger';
import { useCreateQueryClientProvider } from '@lib/query';

const { clearSessionStorage } = storage();

type ProviderProps = {
  children: ReactNode;
};

type ErrorType = {
  status: number;
  message: string;
};
export const Provider: React.FC<ProviderProps> = ({ children }) => {
  const { addMessage: addToastMessage } = useMessaging();
  const router = useRouter();

  const tError = useTranslation('ERROR');

  const QueryClientProvider = useCreateQueryClientProvider({
    queryClientConfig: {
      defaultOptions: {
        queries: {
          onError: (error) => {
            const err = error as ErrorType;

            if (err.message.includes('401')) {
              addToastMessage({
                message: tError('SERVER_ERROR.UNAUTHORIZED')
              });
              router.replace('/login');
              clearSessionStorage();
              resetStores();
            } else {
              addToastMessage({
                message: tError('SERVER_ERROR.DESC')
              });
            }
            logger.error('Query error: ', error);
          }
        },
        mutations: {
          onError: (error) => {
            addToastMessage({
              message: tError('SERVER_ERROR.DESC')
            });
            logger.error('Query error: ', error);
          }
        }
      }
    }
  });

  useSetFeatureToggleAttributes();

  useEffect(() => {
    // Load features from the GrowthBook API
    FeatureToggleConfig.loadFeatures({ autoRefresh: true });
  }, []);

  return (
    <QueryClientProvider>
      <FeatureToggleProvider config={FeatureToggleConfig}>
        <ErrorBoundary>{children}</ErrorBoundary>
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          panelPosition="right"
        />
      </FeatureToggleProvider>
    </QueryClientProvider>
  );
};
