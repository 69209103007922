import { isDev } from '@core/env-config';
import { logger } from '@lib/logger';

import GoogleTagManagerTransport from './GoogleTagManagerTransport';
import { TrackingTransport } from './types';

const MANDATORY_TRANSPORTS: TrackingTransport[] = [GoogleTagManagerTransport];
if (isDev) {
  MANDATORY_TRANSPORTS.push({
    name: 'stdout',
    trackEvent: (name, params) =>
      new Promise((resolve) => {
        logger.log(`[TRACKER]: ${name}, ${params}`);
        resolve();
      })
  });
}

export { MANDATORY_TRANSPORTS };
