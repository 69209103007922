import React from 'react';

import PrivacyImg from '@assets/images/privacy-img.webp';
import { useLfPrivacy } from '@core/content-management';
import { NextPageWithLayout } from '@pages/_app';

import { RichTextPage } from '../components';
import Footer from '../components/Footer';

const LfPrivacySejfaPage: NextPageWithLayout = () => {
  const { data: privacyPageData } = useLfPrivacy();

  const lfPrivacyData = privacyPageData?.fields?.textContent;
  return (
    <>
      <div className="min-w-screen min-h-screen flex-1 rounded-b-[32px] bg-white md:min-h-[900px]">
        {lfPrivacyData && (
          <RichTextPage src={PrivacyImg} Text={lfPrivacyData ?? undefined} />
        )}
      </div>
      <div className="bg-black pb-12 md:p-20">
        <Footer />
      </div>
    </>
  );
};

export default LfPrivacySejfaPage;
