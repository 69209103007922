import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { ChangeCardResponse, ChangeCardRequest, mutations } from '@core/api';
import env from '@core/env-config';
import { useMessaging } from '@core/messaging';
import { useUpdateCardQueryParameters } from '@core/payment';
import { useTranslation } from '@core/translation';
import { ProfileData } from '@core/types';
import {
  updateProfileStore,
  useSelectFromProfileStore
} from '@features/onboarding';
import { logger } from '@lib/logger';
import { useLocation } from '@lib/utils';

import usePaymentQueryParameters from './usePaymentQueryParameters';

type Props = {
  onSuccess: () => void;
};

const useUpdateCard = ({ onSuccess }: Props) => {
  const { useChangeCard, useChangeCardStatus } = mutations.policies;
  const { push } = useRouter();
  const { location } = useLocation();
  const { paymentId } = useUpdateCardQueryParameters();
  const storePolicyId = updateProfileStore('updateProfile');
  const { addMessage } = useMessaging();
  const t = useTranslation('PROFILE');
  const languageParam = usePaymentQueryParameters();

  const policyId = useSelectFromProfileStore(
    (state: ProfileData) => state.policyId
  );

  const handleChangeCardSuccess = (response: ChangeCardResponse) => {
    const paymentLink = response.result.hostedPaymentPageUrl + languageParam;
    push(paymentLink);
  };

  const { mutate, isLoading } = useChangeCard({
    onSuccess: ({ data }) => handleChangeCardSuccess(data.data)
  });

  const handleChangeCardStatusSuccess = () => {
    addMessage({
      message: t('CARD_UPDATED'),
      toastType: 'success'
    });
    onSuccess();
    logger.log('Card status changed successfully');
  };

  const { mutate: mutateCardStatus, isLoading: isChangeCardStatusLoading } =
    useChangeCardStatus({
      onSuccess: handleChangeCardStatusSuccess
    });

  const updateCardStatus = useCallback(() => {
    mutateCardStatus({
      paymentId,
      policyId
    });
  }, [policyId, paymentId]);

  useEffect(() => {
    if (paymentId && policyId) {
      updateCardStatus();
    }
  }, [paymentId, policyId, updateCardStatus]);

  const updateCard = (policy: Pick<ChangeCardRequest, 'policyId'>) => {
    if (!policy.policyId || !location || !env.PAYMENT_TERMS_URL) {
      logger.error('Missing required parameters to update card', {
        policyId: policy.policyId,
        location: location,
        env: env.PAYMENT_TERMS_URL
      });
      return;
    }

    storePolicyId({ policyId: policy.policyId });

    mutate({
      policyId: policy.policyId,
      returnUrl: location.href,
      cancelUrl: location.href,
      termsUrl: env.PAYMENT_TERMS_URL,
      commercePlatformTag: ''
    });
  };

  return {
    updateCard,
    isUpdatingCard: isLoading || isChangeCardStatusLoading
  };
};

export default useUpdateCard;
