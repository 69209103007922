import React from 'react';

import { useWindow } from '@lib/utils';

import { useLandingPageTranslation } from '../translation';

const Hero = () => {
  const t = useLandingPageTranslation();
  const { hasWindow } = useWindow();
  return (
    <div className="relative h-[64dvh] rounded-2xl md:h-[76vh] md:max-h-[76vh] md:rounded-[32px]">
      {hasWindow && (
        <>
          <div className="relative h-full overflow-hidden rounded-2xl  md:rounded-[32px]">
            <video
              width="100%"
              height="100%"
              muted
              autoPlay
              playsInline
              className="h-full w-full object-cover"
              loop
              preload="auto"
            >
              <source src="/hero-video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex items-end justify-center rounded-2xl bg-overlay p-4 pb-12 md:items-center md:rounded-[32px] md:pl-28 ">
            <div className="flex max-w-[1216px] flex-1">
              <div className="max-w-[1216px] whitespace-pre-line text-5xl font-bold text-white md:text-[140px]">
                {t('HERO_TEXT')}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Hero;
