import { getActiveSpan } from '@sentry/nextjs';
import axios from 'axios';

import env from '@core/env-config';
import storage from '@core/storage';

const activeSpan = getActiveSpan();

export const axiosWithAuth = axios.create({
  headers: {
    apiKey: env.INSURANCE_IN_A_BOX_KEY || '',
    authKey: storage().accessToken ?? ''
  }
});

axiosWithAuth.interceptors.request.use(
  function (config) {
    const { accessToken: token, authKey } = storage();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Correlation-Id'] = activeSpan?.traceId;
    config.headers.apiKey = env.INSURANCE_IN_A_BOX_KEY || '';
    config.headers.authKey = authKey ?? '';

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
