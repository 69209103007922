import { FC, ReactNode } from 'react';

import { Block } from '@components/index';

type ChatPageBlockProps = {
  children?: ReactNode;
};

const ChatPageBlock: FC<ChatPageBlockProps> = ({ children }) => (
  <Block className="no-scrollbar grid h-full justify-between overflow-auto bg-white p-0 md:grid-cols-4 md:p-0">
    <div className="hidden md:block" />
    <div className="col-span-2 flex flex-col justify-end rounded-2xl pb-0 md:rounded-[32px] md:pb-4">
      {children}
    </div>
  </Block>
);

export default ChatPageBlock;
