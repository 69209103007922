import { capitalize, capitalizeAll } from './capitalize';
import { cn } from './cn';
import { convertNumericStringToNumber } from './convertNumericStringToNumber';
import { delay } from './delay';
import { generateUUID as uuid } from './generateUUID';
import { getIndexedArray } from './getIndexedArray';
import { getRandomElementInArray } from './getRandomElementInArray';
import { getRandomNumberInRange } from './getRandomNumberInRange';
import { includes } from './includes';
import { isNumber } from './isNumber';
import {
  isIsoDate,
  isNumeric,
  isPostalCode,
  isEmail,
  isUrl,
  isPhoneNumber
} from './regex';
import { removeSpacesFromString } from './removeSpacesFromString';
import roundNumber from './roundNumber';
import { sameDay } from './sameDay';
import { sortArrayOfObjectsByProperty } from './sortArraysOfObjectsByProperty';
import { useDebounce } from './useDebounce';
import { useInterval } from './useInterval';
import useLocation from './useLocation';
import { useTimeout } from './useTimeout';
import { useToggle } from './useToggle';
import { useTypedRouter } from './useTypedRouter';
import useWindow from './useWindow';

export {
  cn,
  isPhoneNumber,
  roundNumber,
  delay,
  useInterval,
  useToggle,
  includes,
  useTimeout,
  removeSpacesFromString,
  useDebounce,
  getRandomNumberInRange,
  getRandomElementInArray,
  sortArrayOfObjectsByProperty,
  getIndexedArray,
  convertNumericStringToNumber,
  useWindow,
  capitalize,
  sameDay,
  capitalizeAll,
  isNumeric,
  isIsoDate,
  isPostalCode,
  isEmail,
  isUrl,
  uuid,
  useLocation,
  useTypedRouter,
  isNumber
};
