import React, { Component, ReactNode } from 'react';

import { logger } from '@lib/logger';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    logger.error('Error boundary error', error);
  }

  //TODO: update error message with translation
  public render() {
    if (this.state.hasError) {
      return (
        <h1 className="p-8 text-white">
          Oväntat fel, vänligen kontakta support om problemet kvarstår
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
