import { useFeatureIsOn, useFeatureValue } from '../hooks';
import { AppFeatures } from '../types';

type UseFeatureResult<AppFeatureId extends keyof AppFeatures> = {
  enabled: boolean;
  value: AppFeatures[AppFeatureId] | null;
};

const useFeature = <AppFeatureId extends keyof AppFeatures>(
  featureName: AppFeatureId & string
): UseFeatureResult<AppFeatureId> => {
  const enabled = useFeatureIsOn(featureName);
  const value = useFeatureValue(featureName);

  return {
    enabled,
    value
  };
};

export default useFeature;
