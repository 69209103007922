import { checkSimulationStatusData } from '../requests';
import { createMutationWithMock } from '../store';

const mockSimulationResult = { isValidSimulation: true, premiumAmount: 100 };

const useCheckSimulationStatusData = createMutationWithMock(
  { request: checkSimulationStatusData },
  mockSimulationResult
);

const common = {
  useCheckSimulationStatusData
};

export default common;
