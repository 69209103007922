import axios from 'axios';

import { UserInfoResponse, UserInfoRequest } from './types';
import { createRequest } from '../../api-definition';

export const getUserInfo = createRequest<UserInfoResponse, void>({
  path: 'Party/UserInfo',
  method: 'GET'
});

export const updateUserInfo = (parameters: UserInfoRequest) =>
  axios.put<UserInfoResponse>('/api/profile/update', parameters);
