import React from 'react';

import Image from 'next/image';

import Background from '@assets/images/safety-by-cilia-header.webp';
import Heart from '@assets/images/safety-by-cilia-heart.svg';
import SafetyByCiliaLogo from '@assets/images/safety-by-cilia-logo.svg';
import SejfaLogo from '@assets/sejfa-logo.svg';

const Hero = () => {
  return (
    <div className="relative h-[76dvh] rounded-2xl md:h-[76vh] md:max-h-[76vh] md:rounded-[32px]">
      <div className="relative flex h-full items-center justify-center overflow-hidden rounded-2xl  md:rounded-[32px]">
        <Image
          src={Background}
          alt="Safety by Cilia background"
          priority
          className="absolute z-[-1] h-full w-full object-cover object-bottom"
        />
        <div>
          <div className="z-2 flex flex-col items-center justify-between pt-6 md:flex-row md:pt-0">
            <Image
              src={SejfaLogo}
              alt="Sejfa logo"
              priority
              className="mb-10 ml-5 h-[85px] w-[200px] md:mb-0 md:ml-0 md:h-[170px] md:w-[400px]"
            />
            <Image
              src={Heart}
              alt="Heart"
              priority
              className="md:mr- mb-8 h-[80px] w-[80px] md:mx-16 md:mb-0 md:h-[112px] md:w-[112px]"
            />
            <Image
              src={SafetyByCiliaLogo}
              alt="Safety by Cilia logo"
              priority
              className="mr-4 h-[100px] w-[223px] md:mb-6 md:mr-0 md:h-[188px] md:w-[450px]"
            />
          </div>
          <h1 className="mt-8 px-12 text-center text-2xl font-semibold text-white md:mt-0 md:px-0 md:text-5xl md:font-bold">
            Sejfa – i stolt samarbete med Safety By Cilia
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
