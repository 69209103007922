import React from 'react';

import { useTranslation } from '@core/translation';

const SelectArea = ({ onChange }: { onChange: (value: string) => void }) => {
  const t = useTranslation('EARLY_ADOPTER_PAGE');

  const AREAS = [
    {
      id: 'bohuslan',
      title: 'Bohuslän'
    },
    {
      id: 'goteborg',
      title: 'Göteborg'
    },
    {
      id: 'halland',
      title: 'Halland'
    },
    {
      id: 'ostgata',
      title: 'Östgöta'
    },
    {
      id: 'stokholm',
      title: 'Stockholm'
    },
    {
      id: 'alvsborg',
      title: 'Älvsborg'
    },
    {
      id: 'other',
      title: t('OTHER')
    }
  ];

  const handleAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === t('CHOOSE_AREA')) {
      onChange('');
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <div>
      <h5 className="mb-4 mt-6 font-semibold">{t('CHOOSE_AREA')}</h5>
      <select
        id="countries"
        onChange={handleAreaChange}
        className="text-l block h-16 w-full cursor-pointer appearance-none rounded-2xl border bg-black p-4 font-bold text-white"
      >
        <option selected>{t('CHOOSE_AREA')}</option>
        {AREAS.map((item) => (
          <option key={item.id} value={item.title}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectArea;
