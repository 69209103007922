import React from 'react';

import { twMerge } from 'tailwind-merge';

import { useTimeout, useToggle } from '@lib/utils';

import { Icon, IconType } from '..';

export type ToastType = 'error' | 'success' | 'info';

type ToastProps = {
  message: string;
  onClose: () => void;
  type?: ToastType;
};

export const Toast = ({ message, onClose, type = 'error' }: ToastProps) => {
  const { state: isOpen, toggle: toggleOpen } = useToggle();

  useTimeout(toggleOpen, 0);

  useTimeout(onClose, 3500);

  const toastClasses = twMerge(
    'absolute flex w-full items-center bg-error p-4 text-white transition-all duration-500 space-x-4 font-semibold z-10',
    type === 'success' && 'bg-success',
    type === 'info' && 'bg-info'
  );

  const TOAST_ICON: { [key in ToastType]: IconType } = {
    info: 'info-outline',
    success: 'checked',
    error: 'info-outline'
  } as const;

  return (
    <div
      className={toastClasses}
      style={{
        transform: isOpen ? 'translateY(0px)' : 'translateY(-100%)'
      }}
    >
      <Icon name={TOAST_ICON[type]} />
      <div className="text-[16px] text-black">{message}</div>
    </div>
  );
};
