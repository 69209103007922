import React from 'react';

import { twMerge } from 'tailwind-merge';

const LoadingMessage = () => {
  const classes = twMerge(
    'flex w-fit space-x-2 rounded-2xl p-4 bg-pressedFifth animate-fade-in mt-3'
  );

  const dotClasses = twMerge('rounded-full bg-black transition h-1 w-1');

  return (
    <div className={classes}>
      <div className={twMerge(dotClasses, 'animate-dot')}></div>
      <div className={twMerge(dotClasses, 'animate-dot-delay-100')}></div>
      <div className={twMerge(dotClasses, 'animate-dot-delay-200')}></div>
    </div>
  );
};

export default LoadingMessage;
