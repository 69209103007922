import { init, Replay } from '@sentry/nextjs';

import env, { isDev } from '@core/env-config';

const shouldUseDebug = isDev;

// TODO: Decide on reasonable rates
const tracesSampleRateByEnvironment: Record<
  'DEV' | 'STAGING' | 'PROD',
  number
> = {
  DEV: 1.0,
  STAGING: 0.5,
  PROD: 0.25
};
const tracesSampleRate =
  tracesSampleRateByEnvironment[env.ENV as 'DEV' | 'STAGING' | 'PROD'];

export { init, Replay, shouldUseDebug, tracesSampleRate };
