import React, { useRef } from 'react';

import CheckedIcon from '@assets/icons/CheckedIcon';

type Props = {
  text: string;
  onClick: (isChecked: boolean) => void;
};

const Checkbox = ({ text, onClick }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (ref.current) {
      ref.current.checked = !ref.current.checked;
      onClick(ref.current.checked);
    }
  };

  return (
    <div
      className="mt-8 flex cursor-pointer items-center"
      onClick={handleClick}
    >
      <div className="relative flex items-center justify-center">
        <input
          ref={ref}
          id="link-checkbox"
          type="checkbox"
          value=""
          onClick={handleClick}
          className="h-8 w-8 cursor-pointer appearance-none rounded-full border-2 border-black checked:border-transparent checked:bg-black focus:outline-none"
        />
        <div className="absolute">
          <CheckedIcon className="h-6 w-6 text-white" />
        </div>
      </div>
      <label
        htmlFor="link-checkbox"
        className="ms-2 text-xl font-medium text-black"
      >
        {text}
      </label>
    </div>
  );
};

export default Checkbox;
