import Footer from './Footer';
import Hero from './Hero';
import RichTextPage from './RichTextPage';
import SeePriceSection from './SeePriceSection';
import SejfaBlock from './SejfaBlock';
import SubpageLayout from './SubpageLayout';
import TrustPilotBlock from './TrustPilotBlock';
import { TrustPilotWidget } from './TrustPilotWidget';

export {
  Hero,
  SejfaBlock,
  SeePriceSection,
  RichTextPage,
  SubpageLayout,
  TrustPilotWidget,
  TrustPilotBlock,
  Footer
};
