import storage from '@core/storage';
import { ProfileData } from '@core/types';
import { createStore } from '@lib/state-management';

const initialState: ProfileData = {
  policyId: 0
};

const getInitialState = () => {
  const profileJson = storage().profile;

  const state = {
    ...initialState,
    ...(profileJson ? JSON.parse(profileJson) : {})
  };

  return state;
};

const updateFunctions = {
  updateProfile: (profile: Partial<ProfileData>) => (state: ProfileData) => {
    storage().saveProfileStore({ ...state, ...profile });
    return profile;
  },
  resetUser: () => () => {
    return initialState;
  }
};

export const {
  useSelectFromStore: useSelectFromProfileStore,
  updateStore: updateProfileStore,
  resetStore: resetProfileStore
} = createStore(getInitialState(), updateFunctions);
