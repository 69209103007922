import { useEffect } from 'react';

import { FeatureToggleConfig } from '../config';

const useSetFeatureToggleAttributes = () => {
  useEffect(() => {
    FeatureToggleConfig.setAttributes({
      locale: 'sv',
      browser: true
    });
  }, []);
};
export default useSetFeatureToggleAttributes;
