import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

import { Block, Button } from '@components/index';

type Props = {
  rowReverse?: boolean;
  title: string;
  desc: string;
  src: string | StaticImageData;
  list?: string[];
  cta?: () => void;
  ctaTitle?: string;
  titleClassName?: string;
  imgContainerClassName?: string;
  blockClassName?: string;
};

const formatText = (text: string) => {
  return text.split('\n').map((line, index) => {
    // Split the line into segments based on the asterisk
    const parts = line.split(/(\*[^*]+\*)/g); // Match text between asterisks
    return (
      <span key={index}>
        {parts.map((part, partIndex) => {
          // Check if the part is meant to be bold (wrapped in asterisks)
          if (part.startsWith('*') && part.endsWith('*')) {
            return <strong key={partIndex}>{part.slice(1, -1)}</strong>; // Remove the asterisks and wrap in <strong>
          } else {
            return part; // Return the part as is if not bold
          }
        })}
        <br />
      </span>
    );
  });
};

const ImageBlock = ({
  rowReverse,
  title,
  desc,
  src,
  list,
  cta,
  ctaTitle,
  titleClassName,
  imgContainerClassName,
  blockClassName
}: Props) => {
  const imgContainerClasses = twMerge(
    'flex justify-center md:justify-end text-black md:pr-[136px]',
    rowReverse
      ? 'order-first mt-0 md:order-last md:justify-en md:pr-0'
      : 'order-last md:order-first',
    imgContainerClassName
  );

  const textContainerClasses = twMerge(
    'flex flex-col items-center md:items-start justify-center text-black',
    rowReverse ? 'items-end' : ''
  );

  return (
    <Block
      className={twMerge('flex w-full justify-center bg-white', blockClassName)}
    >
      <div className="grid max-w-[1216px] items-center bg-white px-4 py-10 md:grid-cols-2 md:space-y-0 md:py-28">
        <div className={imgContainerClasses}>
          <Image src={src} alt="image-1" priority width={488} height={488} />
        </div>
        <div className={textContainerClasses}>
          <div className="max-w-[592px] pb-6">
            <h3
              className={twMerge(
                'mt-8 text-[32px] font-bold md:mt-0 md:text-[72px]',
                titleClassName
              )}
            >
              {title}
            </h3>
            {desc && (
              <p className="max-w-prose pt-6 text-2xl font-medium md:pt-6">
                {formatText(desc)}
              </p>
            )}
            {list && (
              <ul className="p-4">
                {list.map((item) => (
                  <li className="text-2xl font-medium" key={item}>
                    <div className="flex space-x-4">
                      <div className="mt-4 h-[3px] min-h-[3px] w-[3px] min-w-[3px] rounded-full bg-black" />
                      <span>{item}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {cta && ctaTitle ? (
              <Button
                onClick={cta}
                className="mt-6 h-[72px] w-full bg-black text-white md:mt-10 md:max-w-[426px]"
                variant="primary"
              >
                {ctaTitle}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default ImageBlock;
