import { useRouter } from 'next/router';

import { useFeature } from '@core/feature-toggles';

export const useSoftWall = () => {
  const router = useRouter();
  const softWallFromUrl = router.query['soft-wall'];
  const { enabled: softWallEnabled, value: softWallValue } =
    useFeature('soft-wall');

  return {
    enabled: softWallEnabled ? softWallValue !== softWallFromUrl : false
  };
};
