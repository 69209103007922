import React, { useState } from 'react';

import { RadioButton } from '@components/index';

import PaymentMethodIcons from './PaymentMethodIcons/PaymentMethodIcons';
import { updatePriceStore, useSelectFromPriceStore } from '../stores';
import { useOnboardingTranslation } from '../translation';

export type PaymentFrequencyOption = {
  id: 1 | 2;
  value: string;
};

const PaymentFrequencyOptions = () => {
  const { paymentPeriodId } = useSelectFromPriceStore((state) => state);

  const updatePrice = updatePriceStore('updatePrice');
  const t = useOnboardingTranslation();
  const paymentFrequencyOptions: PaymentFrequencyOption[] = [
    {
      id: 2,
      value: t('PRICE_SUGGESTION_SCREEN.MONTH')
    },
    {
      id: 1,
      value: t('PRICE_SUGGESTION_SCREEN.YEAR')
    }
  ];
  const [paymentType, setPaymentType] = useState<PaymentFrequencyOption>(
    paymentFrequencyOptions.find((item) => item.id === paymentPeriodId) ||
      paymentFrequencyOptions[0]
  );

  const handleOptionChange = (selectedOption: PaymentFrequencyOption) => {
    setPaymentType(selectedOption);
    updatePrice({ paymentPeriodId: selectedOption.id });
  };

  return (
    <div>
      <p className="my-4 text-2xl font-semibold leading-tight">
        {t('PRICE_SUGGESTION_SCREEN.SELECT_PAYMENT_FREQUENCY')}
      </p>
      <div>
        <ul>
          {paymentFrequencyOptions.map((item) => (
            <li key={item.id}>
              <RadioButton
                checked={paymentType?.id === item.id}
                onChange={() => handleOptionChange(item)}
                value={item.value}
                RightIcon={() => (
                  <PaymentMethodIcons isYearlyPayment={item.id === 1} />
                )}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PaymentFrequencyOptions;
