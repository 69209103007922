import { useState } from 'react';

import { QueryObserverResult, UseQueryResult } from '@tanstack/react-query';

import { getRandomNumberInRange, useTimeout } from '@lib/utils';

import { UseQueryParams, createQuery } from './query';
import { QueryKeyDefinition } from './types';

type CreateMockQueryOptions = {
  minLoadingDuration?: number;
  maxLoadingDuration?: number;
};

/*
  Add additional fields to picked from result when in need to extend result
  TODO: extend this to support enabled and onSuccess
*/
type CreateMockQueryResult<
  QueryKey extends QueryKeyDefinition<unknown>,
  KeyName extends QueryKey['name'],
  Result,
  ErrorType
> = (
  ...params: UseQueryParams<QueryKey, KeyName, Result, ErrorType>
) => Pick<
  UseQueryResult<Result, ErrorType>,
  | 'data'
  | 'isLoading'
  | 'isInitialLoading'
  | 'error'
  | 'refetch'
  | 'isRefetching'
>;

const defaultMockOptions = {
  minLoadingDuration: 500,
  maxLoadingDuration: 2000
};

export const createMockQuery = <
  QueryKey extends QueryKeyDefinition<unknown>,
  KeyName extends QueryKey['name'],
  Result,
  ErrorType
>(
  query: ReturnType<typeof createQuery<QueryKey, KeyName, Result, ErrorType>>,
  mockData: Result,
  options?: CreateMockQueryOptions
): CreateMockQueryResult<QueryKey, KeyName, Result, ErrorType> => {
  const defaultRefetchResult: QueryObserverResult<Result, ErrorType> = {
    data: undefined,
    dataUpdatedAt: 0,
    error: null,
    errorUpdatedAt: 0,
    failureCount: 0,
    failureReason: null,
    errorUpdateCount: 0,
    isError: false,
    isFetched: false,
    isFetchedAfterMount: false,
    isFetching: false,
    isLoading: true,
    isLoadingError: false,
    isInitialLoading: false,
    isPaused: true,
    isPlaceholderData: false,
    isPreviousData: false,
    isRefetchError: false,
    isRefetching: false,
    isStale: false,
    isSuccess: false,
    refetch: async () => defaultRefetchResult,
    remove: () => null,
    status: 'loading',
    fetchStatus: 'idle'
  };
  const useMockQuery = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...params: UseQueryParams<QueryKey, KeyName, Result, ErrorType>
  ) => {
    const [result, setResult] = useState<
      ReturnType<CreateMockQueryResult<QueryKey, KeyName, Result, ErrorType>>
    >({
      data: undefined,
      isLoading: true,
      isInitialLoading: true,
      isRefetching: false,
      error: null,
      refetch: async () => defaultRefetchResult
    });
    const {
      minLoadingDuration = defaultMockOptions.minLoadingDuration,
      maxLoadingDuration = defaultMockOptions.maxLoadingDuration
    } = options ?? {};
    const loadingDuration = getRandomNumberInRange(
      minLoadingDuration,
      maxLoadingDuration
    );
    useTimeout(() => {
      setResult((prev) => ({
        ...prev,
        data: mockData,
        isLoading: false,
        isInitialLoading: false,
        isRefetching: false,
        error: null
      }));
    }, loadingDuration);
    return result;
  };
  return useMockQuery;
};
