import React, { SVGAttributes } from 'react';

const CheckedIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.18161 12.4869C5.18161 12.4869 8.00582 15.0181 9.50582 17.0181C13.0058 12.0181 19.5002 6.00064 19.5002 6.00064"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  );
};
export default CheckedIcon;
