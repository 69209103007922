import React from 'react';

import { IconButton, Link, Modal } from '@components/index';

import { useOnboardingTranslation } from '../translation';

type InsuranceProviderModalProps = {
  onClose: () => void;
};

const InsuranceProviderModal = ({ onClose }: InsuranceProviderModalProps) => {
  const t = useOnboardingTranslation();

  const data = {
    fields: {
      title: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TITLE'),
      texts: [
        {
          fields: {
            body: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TEXT_1')
          }
        },
        {
          fields: {
            body: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TEXT_2')
          }
        },
        {
          fields: {
            body: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TEXT_3')
          }
        },
        {
          fields: {
            link: '/personuppgifter',
            body: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TEXT_4')
          }
        },
        {
          fields: {
            link: '/cookies',
            body: t('PRICE_SUGGESTION_SCREEN.INSURANCE_INFO_MODAL.TEXT_5')
          }
        }
      ]
    }
  };

  return (
    <Modal>
      <div className="relative">
        <div className="no-scrollbar relative h-[100dvh] w-[100dvw] overflow-scroll rounded-2xl bg-white px-4 py-16 md:h-[90vh] md:w-[76vw] md:rounded-[32px] md:px-12">
          <h1 className="mb-8 overflow-hidden break-normal text-[32px] font-semibold text-black md:mb-4 md:text-[56px]">
            {data?.fields.title}
          </h1>
          <div className="space-y-4">
            {data?.fields.texts.map((item, index) => (
              <p key={index} className="text-[24px] text-black ">
                {item?.fields.link && (
                  <Link
                    target="_blank"
                    link={item.fields.link}
                    title="Tryck här"
                    className="mr-2 text-[24px] text-primary"
                  />
                )}
                {item?.fields.body}
              </p>
            ))}
          </div>
        </div>
        <IconButton
          name="close"
          onClick={onClose}
          className="absolute right-4 top-4 bg-primary"
        />
      </div>
    </Modal>
  );
};

export default InsuranceProviderModal;
