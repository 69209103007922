import React from 'react';

import { sv } from 'date-fns/locale';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale
} from 'react-datepicker';
registerLocale('se', sv);
import 'react-datepicker/dist/react-datepicker.css';
import { twMerge } from 'tailwind-merge';

import { date as dateLib } from '@lib/date';

import { Button, IconButton } from '..';

type DatepickerProps = {
  onDateChange: (date: Date) => void;
  date: Date;
  maxDate?: Date | null | undefined;
  title?: string;
};

const Datepicker = ({
  onDateChange,
  date,
  maxDate,
  title
}: DatepickerProps) => {
  const getDayClassName = (d: Date) => {
    const isPast = dateLib.isPast(d);
    const classes = 'hover:rounded-full';
    if (dateLib.isSameDay(d, date)) {
      return twMerge(classes, 'bg-primary rounded-full');
    }

    return twMerge(classes, !isPast && 'active:bg-primary');
  };

  const renderCustomHeader = ({
    increaseMonth,
    decreaseMonth,
    monthDate,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }: ReactDatePickerCustomHeaderProps) => {
    const iconButtonClasses = 'h-8 w-8';
    const disabledIconButtonClasses =
      ' opacity-20 hover:opacity-20 active:opacity-20 disabled:bg-transparent';

    return (
      <div>
        {!!title && (
          <div className="border-b-[1px] border-solid border-disabled p-4 text-left text-lg font-semibold">
            {title}
          </div>
        )}
        <div className="mt-2 flex items-center justify-between px-4 text-sm font-semibold capitalize">
          <IconButton
            name="arrow-down"
            disabled={prevMonthButtonDisabled}
            className={twMerge(
              iconButtonClasses,
              'rotate-90',
              prevMonthButtonDisabled && disabledIconButtonClasses
            )}
            onClick={decreaseMonth}
          />
          {dateLib.format(monthDate, 'MMMM')}
          <IconButton
            name="arrow-down"
            className={twMerge(
              iconButtonClasses,
              '-rotate-90',
              nextMonthButtonDisabled && disabledIconButtonClasses
            )}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </div>
      </div>
    );
  };

  const getTitle = () => {
    if (dateLib.isToday(date)) {
      return dateLib.formatRelative(date, new Date()).split(' ')[0];
    } else {
      return dateLib.format(date, 'yyyy-MM-dd');
    }
  };

  return (
    <DatePicker
      withPortal
      minDate={new Date()}
      startDate={new Date()}
      className="h-14 w-full cursor-pointer rounded-xl bg-black px-4 font-bold text-white focus:outline-none"
      dayClassName={getDayClassName}
      selected={date || new Date()}
      onChange={onDateChange}
      dateFormat="yyyy-MM-dd"
      locale="se"
      maxDate={maxDate}
      renderCustomHeader={renderCustomHeader}
      customInput={
        <Button className="w-full justify-start capitalize">
          {getTitle()}
        </Button>
      }
    />
  );
};

export default Datepicker;
