import {
  CheckSimulationStatusRequest,
  CheckSimulationStatusResponse,
  EmailVerificationResponse
} from './types';
import { createRequest } from '../../api-definition';

export const checkEmailVerificationStatus = createRequest<
  EmailVerificationResponse,
  void
>({
  path: 'Common/CheckEmailVerificationStatus',
  method: 'GET'
});

export const checkSimulationStatusData = createRequest<
  CheckSimulationStatusResponse,
  CheckSimulationStatusRequest
>({
  path: 'Common/CheckSimulationStatusData',
  method: 'POST'
});
