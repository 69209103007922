import { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { Message } from '@core/types';

type ChatMessageProps = {
  message: Message & {
    links?: {
      text: string;
      url: string;
    }[];
  };
  className?: string;
};

const ChatMessageLink = ({
  children,
  url
}: PropsWithChildren<{ url: string }>) => {
  return (
    <span>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="text-l font-medium text-[#7753A0] underline hover:cursor-pointer active:opacity-60 md:text-xl"
      >
        {children}
      </a>
    </span>
  );
};

const ChatMessage: FC<ChatMessageProps> = ({ className, message }) => {
  const classes = twMerge(
    'w-fit rounded-2xl p-4 duration-500 ease-in ease-out max-w-[320px] md:max-w-[450px] animate-fade-in',
    message.type === 'sender' ? 'bg-primary' : 'bg-pressedFifth',
    className
  );

  return (
    <div className={classes} id={message.id}>
      <span className="text-l md:text-xl">
        {message?.links && message?.links?.length > 0 ? (
          message.message.split(' ').map((word, index) => {
            let link;

            const isWordALink = message.links
              ?.map((item) => item.text)
              .includes(word);

            if (isWordALink) {
              link = message.links?.find((item) => item.text === word);
            }

            if (link) {
              return (
                <ChatMessageLink
                  key={word + index}
                  url={link?.url || ''}
                >{` ${word}`}</ChatMessageLink>
              );
            }
            return ` ${word} `;
          })
        ) : (
          <>
            {message.message}
            {message.linkText && message.link && (
              <>
                {!!message.linkRowBreak && <br />}
                <span
                  onClick={message.action}
                  className="text-l font-medium text-[#7753A0]  underline hover:cursor-pointer active:opacity-60 md:text-xl"
                >{` ${message.linkText}`}</span>
              </>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default ChatMessage;
