import React from 'react';

import { cn } from '@lib/utils';

type Props = {
  className?: string;
};

const Divider = ({ className }: Props) => {
  const baseClasses = cn('h-0.5 w-full bg-disabled', className);

  return <hr className={baseClasses} />;
};

export default Divider;
