import { Method } from 'axios';

export const getParameterOptions = <Parameters = void>(
  method: Method,
  parameters: Parameters
): { params: Parameters } | { data: Parameters } => {
  if (method === 'GET' || method === 'DELETE') {
    return { params: parameters };
  }
  return { data: parameters };
};
