import React from 'react';

import { IconButton, Modal } from '@components/index';
import { UserInfoFormValues } from '@core/types';

import YourInformationBlock from './YourInformationBlock';
import { updateUserStore, useSelectFromUserStore } from '../stores';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

const EditUserInformationModal = ({ onClose, onSubmit }: Props) => {
  const { age, residents, postalCode } = useSelectFromUserStore(
    (state) => state
  );

  const updateUser = updateUserStore('updateUser');

  const handleOnClose = () => {
    onClose();
  };

  const handleSubmit = (data: UserInfoFormValues) => {
    updateUser(data);
    handleOnClose();
    onSubmit();
  };

  return (
    <Modal>
      <div className="relative h-full  w-auto md:h-auto">
        <YourInformationBlock
          onSubmit={handleSubmit}
          age={age}
          residents={residents}
          postalCode={postalCode}
        />
        <IconButton
          name="close"
          onClick={handleOnClose}
          className="absolute right-4 top-4 bg-primary md:right-8 md:top-8"
        />
      </div>
    </Modal>
  );
};

export default EditUserInformationModal;
