/* eslint-disable no-console */
import { captureException } from '@sentry/react';

import { isDev } from '@core/env-config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LoggerParams = Record<string, unknown>;

type Logger = {
  error: (msg: string, error: unknown, params?: LoggerParams) => void;
  warn: (msg: string, params?: LoggerParams) => void;
  log: (msg: string, params?: LoggerParams) => void;
};

const error: Logger['error'] = (msg, error, params) => {
  if (isDev) {
    console.error(msg, error, params);
  } else {
    const errorString =
      typeof error === 'object' ? JSON.stringify(error) : error;
    captureException(new Error(`Message: ${msg}, Error: ${errorString}`), {
      extra: params
    });
  }
};

const warn: Logger['warn'] = (msg, params) => {
  if (isDev) {
    console.warn(msg, params);
  }
};

const log: Logger['log'] = (msg, params) => {
  if (isDev) {
    console.log(msg, params);
  }
};

export const logger: Logger = {
  error,
  warn,
  log
};
