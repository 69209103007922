import SafetyByCiliApp from '@assets/images/safety-by-cilia-app.webp';
import TermsAndConditionsImg from '@assets/images/safety-by-cilia-terms.webp';
import ThisIsHowYouDoItImg from '@assets/images/terms-img.webp';
import { Footer, useLandingPageNavigation } from '@features/landing-page';
import { NextPageWithLayout } from '@pages/_app';

import Hero from '../components/Hero';
import ImageBlock from '../components/ImageBlock';
import { DISCOUNT_CODES_BY_DISCOUNT } from '../constants';

export const SafetyByCiliaPage: NextPageWithLayout = () => {
  const { navigateToOnboarding } = useLandingPageNavigation();

  const sections = [
    {
      title:
        'Skaffa Sejfa så bjuder vi på 1 års abonnemang med Safety By Cilia appen.',
      desc: 'Sejfa är en helt digital hemförsäkring. Den är enkel att teckna. Den ger dig full koll på allt som ingår. Den ger dig ett riktigt bra skydd - till ett riktigt bra pris. Med oss är du i sejfa händer!',
      imgSrc: SafetyByCiliApp,
      rowReverse: true
    },
    {
      title: 'Så här gör du',
      desc: `Teckna 12 månaders hemförsäkring med Sejfa så mailas du en unik kod inom 24 timmar att använda vid nedladdning av Safety By Cilia appen, koden ger dig 1 års gratis medlemskap, värde 699kr\n\nSa vi att du dessutom får 40% rabatt på din hemförsäkring första året?`,
      imgSrc: ThisIsHowYouDoItImg,
      rowReverse: false
    },
    {
      title: 'Regler och villkor',
      desc: '',
      imgSrc: TermsAndConditionsImg,
      rowReverse: true,
      list: [
        'Du kan inte vara kund hos Sejfa sedan tidigare',
        'Du måste teckna hemförsäkring på 12 månader.',
        'Endast ett erbjudande per hushåll kan användas.',
        'Sista datum för erbjudandet är 31 December.',
        'Villkor för att du ska få en Safety by Cilia abonnemanget är att du har din hemförsäkring tecknad hos Sejfa 12 månader framåt, förutsatt att du fortsatt har ett behov av försäkring som Sejfas försäkring täcker. Skulle du säga upp din försäkring innan 12 månader har gått från dagen då försäkringen tecknades blir du återbetalningsskyldig för värdet av abonnemanget (699 kr).'
      ]
    }
  ];

  const openOnboardingWithDiscountCode = (code: string) => {
    navigateToOnboarding(code);
  };

  return (
    <div className="space-y-0.5 rounded-2xl md:rounded-[32px]">
      <Hero />
      <ImageBlock
        key={'first'}
        src={sections[0].imgSrc}
        rowReverse={sections[0].rowReverse}
        title={sections[0].title}
        desc={sections[0].desc}
        titleClassName="md:text-7xl font-bold"
        imgContainerClassName="order-last pt-0"
        blockClassName="space-y-4"
      />
      <ImageBlock
        key={'second'}
        src={sections[1].imgSrc}
        rowReverse={sections[1].rowReverse}
        title={sections[1].title}
        desc={sections[1].desc}
        cta={() =>
          openOnboardingWithDiscountCode(DISCOUNT_CODES_BY_DISCOUNT[40])
        }
        ctaTitle="Signa upp på 2 min"
        imgContainerClassName="order-first pt-0"
      />

      <ImageBlock
        key={'second'}
        src={sections[2].imgSrc}
        rowReverse={sections[2].rowReverse}
        title={sections[2].title}
        desc={sections[2].desc}
        list={sections[2].list}
        imgContainerClassName="max-h-[488px]"
      />
      <div className="flex justify-center px-4 pb-12 md:py-20">
        <div className="w-full max-w-[1216px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};
