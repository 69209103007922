import { deniedOffer, cancelledOffer, earlyAdopterOffer } from '../requests';
import { ambassadorSignupOffer } from '../requests/offers/requests';
import { createMutation } from '../store';

const useDeniedOffer = createMutation({
  request: deniedOffer
});

const useCancelledOffer = createMutation({
  request: cancelledOffer
});

const useEarlyAdopterOffer = createMutation({
  request: earlyAdopterOffer
});

const useAmbassadorSignupOffer = createMutation({
  request: ambassadorSignupOffer
});

export {
  useDeniedOffer,
  useCancelledOffer,
  useEarlyAdopterOffer,
  useAmbassadorSignupOffer
};
