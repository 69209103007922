import { Block } from '@components/index';

import { TrustPilotWidget } from './TrustPilotWidget';
import { useLandingPageTranslation } from '../translation';

const TrustPilotBlock = () => {
  const t = useLandingPageTranslation();

  return (
    <Block className="p-hp flex w-full flex-col items-center justify-center bg-white py-14 md:py-28">
      <h3 className="mb-6 text-center text-[40px] font-semibold md:mb-12 md:text-[56px]">
        {t('TRUSTPILOT_SECTION.TITLE')}
      </h3>
      <TrustPilotWidget />
    </Block>
  );
};

export default TrustPilotBlock;
