import { updateUserInfo, UserInfoResponse } from '../requests';
import { createMutation } from '../store';

const useUpdateUserInfo = createMutation({
  request: updateUserInfo,
  dependentQueries: ['getUserInfo'],
  updateQueryStateOnSuccess: async (queryClient, _, variables) => {
    await queryClient.cancelQueries({ queryKey: ['getUserInfo', undefined] });
    queryClient.setQueryData<UserInfoResponse>(
      ['getUserInfo', undefined],
      (prevData?: UserInfoResponse) => {
        if (!prevData || !variables) {
          return;
        }
        return {
          ...prevData,
          result: {
            ...prevData.result,
            firstName: variables.firstName,
            lastName: variables.lastName,
            emailId: variables.emailId,
            mobileNumber: variables.mobileNumber
          }
        };
      }
    );
  }
});

export default {
  useUpdateUserInfo
};
