import { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from '@components/index';
import { useTranslation } from '@core/translation';

type ChatBackButtonProps = {
  className?: string;
  onClick?: () => void;
};

const ChatBackButton: FC<ChatBackButtonProps> = ({ className, onClick }) => {
  const t = useTranslation('COMMON');

  const classes = twMerge(
    'flex flex-row items-center rounded-xl bg-white p-2 mt-4 self-center duration-500 ease-in-out hover:opacity-60 active:opacity-100 md:mt-4 absolute top-0',
    onClick ? 'visible' : 'invisible',
    className
  );

  return (
    <button onClick={onClick} className={classes}>
      <span className="mr-2 text-base font-bold">{t('BACK')}</span>
      {/* <Image height={16} src={ArrowUp} alt={'arrow_up'} /> */}
      <Icon name="arrow-upward" className="h-4" />
    </button>
  );
};

export default ChatBackButton;
