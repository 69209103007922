import React from 'react';

import CookiesImg from '@assets/images/cookies-img.webp';
import { useCookiesSejfa } from '@core/content-management';
import { NextPageWithLayout } from '@pages/_app';

import { RichTextPage } from '../components';
import Footer from '../components/Footer';

const CookiesPage: NextPageWithLayout = () => {
  const { data: cookiePageData } = useCookiesSejfa();

  const cookieData = cookiePageData?.fields?.textContent;
  return (
    <>
      <div className="min-w-screen min-h-screen flex-1 rounded-b-[32px] bg-white md:min-h-[900px]">
        {cookieData && (
          <RichTextPage src={CookiesImg} Text={cookieData ?? undefined} />
        )}
      </div>
      <div className="bg-black pb-12 md:p-20">
        <Footer />
      </div>
    </>
  );
};

export default CookiesPage;
