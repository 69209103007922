import { useEffect } from 'react';

import {
  mutations,
  CheckSimulationStatusRequest,
  useErrorCodeTranslation
} from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { PriceData } from '@core/types';
import { isNumber } from '@lib/utils';

import useCampaignDiscount from './useCampaignDiscount';
import { useOnboardingNavigation } from '../navigation';
import {
  updatePriceStore,
  useSelectFromPriceStore,
  updateOnboardingStore
} from '../stores';

type Params = {
  age: number;
  postalCode: number;
  numberOfResidents: number;
  email?: string;
  onSuccess?: () => void;
  onFail?: () => void;
};

const useSimulateQuote = ({
  age,
  postalCode,
  numberOfResidents,
  onSuccess,
  onFail
}: Params) => {
  const { addMessage } = useMessaging();
  const { navigateToOnboardingRoot } = useOnboardingNavigation();
  const resetOnboardingStore = updateOnboardingStore('resetOnboarding');
  const priceStore = useSelectFromPriceStore((state: PriceData) => state);
  const tError = useTranslation('ERROR');
  const { getErrorTranslation } = useErrorCodeTranslation();

  const { removeCampaignCode, campaignCode } = storage();
  const {
    discountCode,
    getDiscountCode,
    error: campaignCodeError
  } = useCampaignDiscount();

  const { mutate, isLoading, error } = mutations.quotes.useSimulateQuote({
    onError: (error) => {
      if (campaignCode) {
        removeCampaignCode();
      }
      addMessage({
        message: getErrorTranslation(error?.data?.code),
        payload: error
      });
      navigateToOnboardingRoot();
      resetOnboardingStore();
    },

    onSuccess: (data) => {
      if (data?.result) {
        const {
          isValidSimulation,
          monthlyPremium,
          premiumAmount,
          currency,
          discountMonthlyPremium,
          discountPremiumAmount
        } = data.result;

        const priceData: PriceData = {
          ...priceStore,
          price: premiumAmount,
          monthlyPrice: monthlyPremium,
          discountMonthlyValue: discountMonthlyPremium,
          discountValue: discountPremiumAmount,
          discountCode:
            discountPremiumAmount && discountCode ? discountCode : undefined,
          currency,
          campaignCode,
          isEstimating: false
        };

        updatePriceStore('updatePrice')({ ...priceData });
        if (isValidSimulation) {
          if (!!campaignCode && !isNumber(priceData.discountValue)) {
            addMessage({
              message: tError('INVALID_DISCOUNT_CODE'),
              payload: tError('INVALID_DISCOUNT_CODE')
            });
            removeCampaignCode();
          }
          onSuccess?.();
          return;
        } else {
          onFail?.();
        }
      }
    }
  });

  useEffect(() => {
    const body: CheckSimulationStatusRequest = {
      age: Number(age),
      postalCode: Number(postalCode),
      numberOfResidents: Number(numberOfResidents)
    };
    if (discountCode !== undefined) {
      mutate({ ...body, discountCode });
    } else if (campaignCodeError) {
      removeCampaignCode();
      updatePriceStore('updatePrice')({
        discountCode: undefined,
        campaignCode: undefined
      });
      mutate(body);
    }
  }, [discountCode, campaignCodeError]);

  useEffect(() => {
    if (age && postalCode && numberOfResidents) {
      const body: CheckSimulationStatusRequest = {
        age: Number(age),
        postalCode: Number(postalCode),
        numberOfResidents: Number(numberOfResidents)
      };

      if (campaignCode) {
        getDiscountCode(campaignCode);
      } else {
        mutate(body);
      }
    } else {
      onFail?.();
    }
  }, [mutate, age, postalCode, numberOfResidents, onFail, campaignCode]);

  return { isLoading, error };
};

export default useSimulateQuote;
