import { validateToken } from '../requests';
import { createMutationWithMock } from '../store';

const useValidateToken = createMutationWithMock(
  { request: validateToken },
  {
    result: {
      accessToken: 'mocked-token',
      refreshTokenExpireInSeconds: 3600000,
      refreshToken: 'mocked-refresh-token',
      expireInSeconds: 36000
    }
  }
);

const onboard = {
  useValidateToken
};

export default onboard;
