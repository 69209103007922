import {
  useSejfDeductibleModal,
  useSejfTripModal,
  useSejfValueModal,
  GeneralInsuranceInfoModalContentSkeletonEntry
} from '@core/content-management';

import { useOnboardingTranslation } from '../translation';

export type GeneralInfoType = 'INSURANCE_VALUE' | 'DEDUCTIBLE' | 'TRIP_LENGTH';

export const useGeneralInsuranceInfo = (
  type?: GeneralInfoType
): {
  data?: GeneralInsuranceInfoModalContentSkeletonEntry;
  moreInfo: string;
} => {
  const t = useOnboardingTranslation();
  const { data: sejfValue } = useSejfValueModal();
  const { data: sejfDeductible } = useSejfDeductibleModal();
  const { data: sejfTrip } = useSejfTripModal();
  const modalData = {
    INSURANCE_VALUE: sejfValue,
    DEDUCTIBLE: sejfDeductible,
    TRIP_LENGTH: sejfTrip
  };

  const data = type ? modalData[type] : undefined;
  return {
    data,
    moreInfo: t('GENERAL_MODAL_MORE_INFO')
  };
};
