import { OnboardingData, PriceData, ProfileData, UserInfo } from '@core/types';
import { storage as storageLib } from '@lib/storage';

type StorageKey =
  | 'accessToken'
  | 'authKey'
  | 'onboarding'
  | 'price'
  | 'user'
  | 'profile'
  | 'campaignCode';

const storage = () => {
  const { getItem, setItem, clearStorage, removeItem } = storageLib();

  const getSessionData = (key: StorageKey) => {
    return getItem(key);
  };

  const saveSessionData = (
    key: StorageKey,
    data: PriceData | OnboardingData | UserInfo | ProfileData
  ) => {
    setItem(key, JSON.stringify(data));
  };

  const clearSessionStorage = () => clearStorage();

  const authKey = getSessionData('authKey');
  const accessToken = getSessionData('accessToken');
  const onboarding = getSessionData('onboarding');
  const profile = getSessionData('profile');
  const price = getSessionData('price');
  const user = getSessionData('user');
  const campaignCode = getItem('campaignCode', 'local');
  const referralCode = getItem('referralCode', 'local');

  const saveAccessToken = (token: string) => setItem('accessToken', token);
  const saveAuthKey = (key: string) => setItem('authKey', key);

  const saveOnboardingStore = (data: OnboardingData) =>
    saveSessionData('onboarding', data);

  const saveProfileStore = (data: ProfileData) =>
    saveSessionData('profile', data);

  const savePriceStore = (data: PriceData) => saveSessionData('price', data);

  const saveUserStore = (data: UserInfo) => saveSessionData('user', data);

  const saveCampaignCode = (campaignCode: string) =>
    setItem('campaignCode', campaignCode, 'local');

  const saveReferralCode = (code: string) =>
    setItem('referralCode', code, 'local');

  const removeCampaignCode = () => removeItem('campaignCode', 'local');

  const removeReferralCode = () => removeItem('referralCode', 'local');

  return {
    authKey,
    accessToken,
    onboarding,
    price,
    user,
    campaignCode,
    referralCode,
    profile,
    setItem,
    clearSessionStorage,
    saveAccessToken,
    saveAuthKey,
    saveOnboardingStore,
    savePriceStore,
    saveUserStore,
    saveCampaignCode,
    removeCampaignCode,
    saveReferralCode,
    removeReferralCode,
    saveProfileStore
  };
};

export default storage;
