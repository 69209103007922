import React from 'react';

import Image from 'next/image';

import Logo from '@assets/sejfa-logo.svg';

type Props = {
  width?: number;
  className?: string;
  onClick?: () => void;
};

const SejfaLogo = ({ className, onClick }: Props) => {
  return (
    <Image
      onClick={onClick}
      src={Logo}
      alt="sejfa-logo"
      className={className}
    />
  );
};

export default SejfaLogo;
